import adminGuard from "@/utils/auth/adminGuard";

export default [
  {
    path: "/a",
    name: "AdminIndex",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/index/AdminIndex.vue")
  },
  {
    path: "/a/croppertest/",
    name: "CropperTest",
    component: () => import("@/screensAdmin/prototype/CropperTest.vue")
  }
];
