<script>
import AvatarPipe from "../Avatar/AvatarPipe.vue";
import AvatarView from "../Avatar/AvatarView.vue";
import Link from "../Link/Link.vue";
import Button from "../Button/Button.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Chip from "../Chip/Chip.vue";

export default {
  name: 'TableItem',
  components: {
    AvatarPipe,
    AvatarView,
    Link,
    Button,
    Dropdown,
    Chip,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    messageClick: {
      type: Function,
    },
    reportClick: {
      type: Function,
    },
  },
  methods: {
    handleMessageClick() {
      if (this.messageClick) {
        this.messageClick();
      }
      this.$emit("message-click", this.item);
    },
    handleReportClick() {
      if (this.reportClick) {
        this.reportClick();
      }
      this.$emit("report-click", this.item);
    },
  },
};
</script>

<template>
  <tr class="suaw-table-item">
    <td class="suaw-table-item__data suaw-table-item__data--spacer" />
    <td class="suaw-table-item__data suaw-table-item__data--first">
      <AvatarPipe
        v-if="item.userId"
        :userId="item.userId"
        size="medium"
        class="suaw-table-item__avatar"
      />
      <AvatarView
        v-else
        size="medium"
        :url="item.url"
        class="suaw-table-item__avatar"
      />
      <span
        v-for="(content, index) in item.contents"
        :key="index"
        class="suaw-table-item__text"
      >
        <div class="suaw-table-item__headline">
          <Link
            v-if="content.linkUrl"
            :to="content.linkUrl"
            weight="bold"
            class="suaw-table-item__link"
          >{{ content.text }}</Link>
          <strong v-else>{{ content.text }}</strong>
          <Chip
            v-if="content.RSVPed"
            size="small"
            status="success"
            shape="square"
            icon="IconDownloadDone"
            label="RSVPed"
            chipType="icon"
            :key="content.id ? content.id : index"
          />
        </div>
        <small
          v-if="content.subtext"
          class="suaw-table-item__subtext"
        >{{ content.subtext }}</small>
      </span>
    </td>
    <td class="suaw-table-item__data suaw-table-item__data--last">
      <Button
        v-if="item.button"
        :buttonText="item.button"
        type="ghost"
        @click="handleMessageClick"
      />
      <Dropdown
        v-if="item.dropdownText"
        :label="item.dropdownText"
        :items="item.dropdownItems"
        dropdownType="mutable"
        dropdownButtonSize="medium"
        buttonType="secondary"
      />
      <Dropdown
        v-if="item.dropdownMore"
        :items="item.dropdownItems"
        iconDropdown="IconMoreHorizontal"
        dropdownButtonSize="small"
        buttonType="secondary-outline"
        buttonClassName="suaw-dropdown__button--chip"
        @item-click="$emit('dropdown-item-click', $event)"
      />
      <Button
        v-if="item.buttonCritical"
        :buttonText="item.buttonCritical"
        type="critical-light"
        @click="handleReportClick"
      />
      <slot name="actions" />
    </td>
    <td class="suaw-table-item__data suaw-table-item__data--spacer" />
  </tr>
</template>

<style lang="scss" scoped>
.suaw-table-item {
  display: flex;

  &__data:not(.suaw-table-item__data--spacer) {
    flex-grow: 1;

    .suaw-table-item:not(:last-child) & {
      border-bottom: 1px solid var(--sem-color-border-regular);
    }
  }

  &__data {
    padding-bottom: var(--sem-space-double);
    padding-top: var(--sem-space-double);
  }

  &__data--spacer {
    border-color: transparent;
    width: var(--sem-space-double);
  }

  &__data--first {
    padding-left: 0;
    padding-right: var(--sem-space-double);
  }

  &__data--last {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-left: var(--sem-space-double);
    padding-right: 0;
    text-align: right;
  }

  &__headline,
  &__data--last {
    display: flex;
    gap: var(--sem-space-base);
  }

  &__headline {
    align-items: center;
  }

  &__text {
    display: inline-block;
    margin-left: var(--sem-space-base);
    min-width: 50px;
    vertical-align: middle;

    + .suaw-table-item__text {
      margin-left: var(--sem-space-triple);
    }
  }

  &__subtext {
    display: block;
  }

  &__avatar {
    vertical-align: middle;
  }
}
</style>
