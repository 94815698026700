<script>
import SubHeader from "../SubHeader/SubHeader.vue";

export default {
  name: "MainContent",
  components: {
    SubHeader,
  },
  props: {
    mainItems: {
      type: Array,
    },
    size: {
      type: String,
      default: "large",
      validator: function (value) {
        return (
          ["hero", "large", "large-small-gap", "medium", "small"].indexOf(
            value
          ) !== -1
        );
      },
    },
    showGradient: {
      type: Boolean,
      default: false,
    },
    showSubHeader: {
      type: Boolean,
      default: false,
    },
    subHeaderTitle: String,
    subHeaderDesc: String,
    smallTopMargin: {
      type: Boolean,
      default: false,
    },
    noTopMarginDesktop: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <main
    class="suaw-main-content"
    :class="[
      size === 'hero' ? 'suaw-main-content--hero' : '',
      showGradient ? 'suaw-main-content--gradient' : ''
    ]"
  >
    <SubHeader
      v-if="showSubHeader"
      :headlineTitle="subHeaderTitle"
      :headlineDesc="subHeaderDesc"
    />
    <div
      class="suaw-main-content__holder"
      :class="[
        'suaw-main-content__holder--' + size,
        smallTopMargin ? 'suaw-main-content__holder--top-margin' : '',
        noTopMarginDesktop ? 'suaw-main-content__holder--no-margin-desktop' : ''
      ]"
    >
      <component
        v-for="(item, index) in mainItems"
        :key="index"
        :is="item.component"
        v-bind="item.props"
      />
      <slot />
    </div>
  </main>
</template>

<style lang="scss" scoped>
.suaw-main-content {
  width: 100%;
  min-height: calc(100vh - 466px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.suaw-main-content--hero {
    padding: 0; // Remove padding for hero variant
  }
  &.suaw-main-content--gradient {
    background: linear-gradient(180deg, var(--sem-color-info-light) 0%, var(--sem-color-background-light) 30%);
  }
  // Default padding for other variants
  &:not(.suaw-main-content--hero) {
    padding: 0 var(--sem-space-triple);

    @media (max-width: 767px) {
      padding: 0 var(--sem-space-double);
    }
  }
  &__holder {
    min-height: calc(100vh - 578px); // that height is the height of the header, footer and margin top and bottom of holder
    width: 100%;
    max-width: var(--comp-main-content-width);
    margin: var(--sem-space-septuple) auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      margin: var(--sem-space-triple) auto;
    }
  }

  &__holder--top-margin {
    margin-top: var(--sem-space-triple);
  }

  &__holder--hero {
    margin: 0px auto;
  }

  &__holder--large {
    --comp-main-content-width: 1264px;
    gap: var(--sem-space-septuple);
  }

  &__holder--large-small-gap {
    --comp-main-content-width: 1264px;
    gap: var(--sem-space-triple);
  }

  &__holder--medium {
    --comp-main-content-width: 720px;
    gap: var(--sem-space-quadruple);
  }

  &__holder--small {
    --comp-main-content-width: 400px;
    gap: var(--sem-space-quadruple);
  }

  &__holder--no-margin-desktop {
    @media (min-width: 768px) {
      margin-top: 0px;
    }
  }
}
</style>
