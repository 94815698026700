<script>
import Summary from "../Summary/Summary.vue";
import CardMap from "./CardMap.vue";
import CardOnlineMeeting from "./CardOnlineMeeting.vue";
import CardChapter from "./CardChapter.vue";

export default {
  name: "Card",
  components: {
    CardMap,
    CardOnlineMeeting,
    CardChapter,
    Summary
  },
  props: {
    id: String,
    showHeader: Boolean,
    cardEventName: String,
    cardEventStatus: String,
    cardSpotsLeft: String,
    cardEventRSVPed: Boolean,
    cardEventTime: String,
    cardEventDuration: String,
    cardEventDay: Number,
    cardEventMonth: Number,
    cardEventTimezone: String,
    modalTitle: {
      type: String,
      default: "",
    },
    isOrganizer: {
      type: Boolean,
      default: false
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    modalClickMethod: {
      type: Function,
    },
    cardEventHeadingSize: {
      type: [String, Number],
      default: 4,
    },
    cardType: {
      type: String,
      validator: function (value) {
        return (
          [
            "CardMap",
            "CardOnlineMeeting",
            "CardChapter"
          ].indexOf(value) !== -1
        );
      },
    },
  },
  computed: {
    cardComponentProps() {
      return { ...this.$attrs, id: this.id };
    },
    headerClasses() {
      return {
        'suaw-card__header--footerless': this.showFooter === false
      }
    },
    cardDisplay() {
      let display = [
        "CardChapter"
      ].includes(this.cardType)
        ? "inline"
        : "block";
      
      return display;
    },
  },
};
</script>

<template>
  <div 
    class="suaw-card"
    :class="[
      'suaw-card--' + cardDisplay,
      cardType
    ]"
  >
    <header
      v-if="showHeader"
      class="suaw-card__header"
      :class="headerClasses"
    >
      <slot name="summary">
        <Summary
          summaryType="series"
          :id="id"
          :headingSize="cardEventHeadingSize"
          :eventStatus="cardEventStatus"
          :heading="cardEventName"
          :eventSpotsLeft="cardSpotsLeft"
          :eventRSVPed="cardEventRSVPed"
          :eventTime="cardEventTime"
          :eventDuration="cardEventDuration"
          :eventDay="cardEventDay"
          :eventMonth="cardEventMonth"
          :eventTimezone="cardEventTimezone"
          :modalTitle="modalTitle"
          :modalClickMethod="modalClickMethod"
          @click="$emit('summary-button-click', $event)"
        />
      </slot>
    </header>
    <component
      :is="cardType"
      v-bind="cardComponentProps"
      :showHeader="showHeader"
      :showFooter="showFooter"
      @item-click="$emit('item-click', $event)"
      @click="$emit('click', $event)"
      @link-copied="$emit('link-copied')"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-md);
  display: var(--comp-card-display);
  flex-direction: column;
  padding: var(--sem-space-triple);
  transition: box-shadow .25s linear;

  &--inline {
    --comp-card-display: inline-flex;
    max-width: 256px;
    min-width: 256px;
  }

  &--block {
    --comp-card-display: flex;
  }

  &--carousel {
    min-width: 256px;
  }

  &__header:not(.suaw-card__header--footerless) {
    padding-bottom: var(--sem-space-triple);
  }

  &.CardChapter:hover {
    box-shadow: var(--shadow-2);
  }
}
</style>
