<script>
import ListItem from "./ListItem.vue";

export default {
  name: "List",
  components: {
    ListItem
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemClickable: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    itemClicked(item) {
      if (this.itemClickable) {
        this.$emit("item-click", item);
      }
    }
  }
};
</script>

<template>
  <ul class="suaw-list">
    <ListItem
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :item-clickable="itemClickable"
      @item-click="itemClicked(item)"
      @button-click="$emit('button-click', $event)"
    />
    <slot />
  </ul>
</template>

<style lang="scss" scoped>
.suaw-list {
  background-color: var(--sem-color-background-lightest);
  border: 1px solid var(--sem-color-border-regular);
  border-radius: var(--sem-radius-sm);
  box-shadow: var(--date-square-shadow);
  list-style-type: none;
  overflow: hidden;
  padding: var(--sem-space-base);
}
</style>
