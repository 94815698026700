<script>
import Icon from "../Icon/Icon.vue";
import Pill from "../Pill/Pill.vue";
import AvatarPipe from "../Avatar/AvatarPipe.vue";

export default {
  name: "Chip",
  components: {
    Icon,
    Pill,
    AvatarPipe,
  },
  props: {
    chipType: {
      type: String,
      default: "text",
      validator: function (value) {
        return [
          "text",
          "icon",
          "icon-right",
          "avatar",
          "all"
        ].indexOf(value) !== -1;
      },
    },
    icon: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      validator: function (value) {
        return [
          "default",
          "online",
          "info",
          "success",
          "critical",
        ].indexOf(value) !== -1;
      },
    },
    shape: {
      type: String,
      default: "round",
      validator: function (value) {
        return ["round", "square"].indexOf(value) !== -1;
      },
    },
    iconColor: {
      type: String,
    },
    size: {
      type: String,
      validator: function (value) {
        return ["medium", "small"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "Chip Label",
    },
    pill: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: null,
    },
  },
  computed: {
    shouldDisplayAvatar() {
      return this.chipType == 'avatar' || this.shouldDisplayAll ? true : false
    },
    shouldDisplayIcon() {
      return this.chipType == 'icon' ? true : false
    },
    shouldDisplayIconRight() {
      return this.chipType == 'icon-right' || this.shouldDisplayAll ? true : false
    },
    shouldDisplayAll() {
      return this.chipType == 'all' ? true : false
    },
  },
};
</script>

<template>
  <div 
    class="suaw-chip"
    :class="['suaw-chip--' + status, 'suaw-chip--' + size, 'suaw-chip--' + shape, className]"
    @click="$emit('click')"
  >
    <Icon
      v-if="shouldDisplayIcon"
      class="suaw-chip__icon"
      :icon="icon"
      :color="iconColor"
    />
    <AvatarPipe
      v-if="shouldDisplayAvatar"
      :userId="userId"
      size="small"
    />
    <p
      v-if="value"
      class="suaw-chip__value"
    >
      {{ value }}
    </p>
    <p
      v-if="label"
      class="suaw-chip__text"
    >
      {{ label }}
    </p>
    <Pill
      v-if="pill"
      :text="pill"
      status="lightest"
    />
    <Icon
      v-if="shouldDisplayIconRight"
      class="suaw-chip__icon"
      :icon="icon"
    />
  </div>
</template>

<style lang="scss" scoped>
.suaw-chip {
  align-items: center;
  display: inline-flex;
  position: relative;

  &:not(.suaw-chip--square) {
    border-radius: var(--sem-radius-lg);
  }

  &--square {
    border-radius: var(--sem-radius-sm);
  }

  &--medium {
    //added height in case in flex container
    height: 26px;
    gap: 4px;
    padding: 4px 8px;
  }

  &--small {
    gap: 2px;
    min-height: 24px;
    padding: 2px 6px;
  }

  &--default {
    background-color: var(--sem-color-neutral-light);
  }

  &--online {
    background-color: var(--sem-color-complementary-light);
  }

  &--info {
    background-color: var(--sem-color-info-light);
  }

  &--critical {
    background-color: var(--sem-color-critical-light);
  }

  &--success {
    background-color: var(--sem-color-success-light);
  }

  &--clickable {
    cursor: pointer;
    transition: opacity .25s linear;
  }

  &--clickable:hover {
    opacity: .85;
  }

  &--no-click {
    pointer-events: none;
  }

  &__icon {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
  }

  &__value,
  &__text {
    color: var(--sem-color-text-medium);
    font-size: var(--body-bold-is-size-7-font-size);
    font-style: var(--body-bold-is-size-7-font-style);
    font-weight: var(--body-bold-is-size-7-font-weight);
    position: relative;
    white-space: nowrap;

    .suaw-chip--small & {
      font-family: var(--body-small-bold-font-family);
      font-size: var(--body-small-bold-font-size);
      font-style: var(--body-small-bold-font-style);
      font-weight: 700;
      letter-spacing: var(--body-small-bold-letter-spacing);
      line-height: var(--body-small-bold-line-height);
    }

    .suaw-chip--medium & {
      font-family: var(--body-bold-is-size-7-font-family);
      font-size: var(--body-bold-is-size-7-font-size);
      font-style: var(--body-bold-is-size-7-font-style);
      font-weight: 700;
      letter-spacing: var(--body-bold-is-size-7-letter-spacing);
      line-height: var(--body-bold-is-size-7-line-height);
    }
  }

  &__value,
  &__text {

    .suaw-chip--default & {
      color: var(--sem-color-text-medium);
    }

    .suaw-chip--info & {
      color: var(--sem-color-info-medium);
    }

    .suaw-chip--critical & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-chip--success & {
      color: var(--sem-color-success-medium);
    }

    .suaw-chip--online & {
      color: var(--sem-color-complementary-medium);
    }
  }

  &__icon {

    .suaw-chip--default & {
      fill: var(--sem-color-text-medium);
    }

    .suaw-chip--info & {
      fill: var(--sem-color-info-medium);
    }

    .suaw-chip--critical & {
      fill: var(--sem-color-critical-medium);
    }

    .suaw-chip--success & {
      fill: var(--sem-color-success-medium);
    }

    .suaw-chip--online & {
      fill: var(--sem-color-complementary-medium);
    }
  }
}
</style>
