export default [
  {
    path: "/resources/code-of-conduct",
    name: "CodeOfConduct",
    component: () => import("@/screens/resourcesFooter/CodeOfConduct.vue"),
    alias: ["/about/code-of-conduct", "/code-of-conduct"]
  },
  {
    path: "/resources/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/screens/resourcesFooter/PrivacyPolicy.vue"),
    alias: ["/privacy-policy"]
  },
  {
    path: "/resources/terms-of-service",
    name: "TermsOfService",
    component: () => import("@/screens/resourcesFooter/TermsOfService.vue"),
    alias: ["/tos", "/resources/tos"]
  },
  {
    path: "/resources/legal",
    name: "Legal",
    component: () => import("@/screens/resourcesFooter/Legal.vue"),
    alias: ["/legal"]
  }
];
