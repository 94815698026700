<script>
import Thumbnail from "../Thumbnail/Thumbnail.vue";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
export default {
  name: "ArticleCard",
  components: { Thumbnail, Heading, Paragraph },
  props: {
    id: {
      type: String,
      required: true,
    },
    author: {
      type: String,
    },
    thumbnail: {
      type: String,
    },
    category: {
      type: String,
    },
    heading: {
      type: String,
    },
    preview: {
      type: String,
    },
    posted: {
      type: String,
    },
    route: {
      type: String,
      required: true, // Make it required to ensure every card has a route
    },
  },
};
</script>

<template>
  <router-link :to="route" class="suaw-article-card">
    <div class="suaw-article-card__main-container">
      <div class="suaw-article-card__thumbnail-container">
        <Thumbnail
          class="suaw-article-card__thumbnail"
          :content="thumbnail"
          size="fit"
        />
      </div>
      <div class="suaw-article-card__content-container">
        <div class="suaw-article-card__header">
          <Paragraph
            class="suaw-article-card__author-name"
            weight="normal"
            size="small"
            :text="author"
          />
          <Paragraph
            class="suaw-article-card__posted"
            weight="normal"
            size="small"
            :text="posted"
          />
        </div>
        <Heading
          class="suaw-article-card__heading"
          level="5"
          :content="heading"
        />
        <Paragraph
          v-if="preview"
          class="suaw-article-card__preview"
          weight="normal"
          size="small"
          :text="preview"
        />
      </div>
    </div>
  </router-link>
</template>

<style lang="scss" scoped>
.suaw-article-card {
  height: 355px;
  width: 100%;
  text-decoration: none;
  transition: transform 0.25s ease;
  display: block;
  &:hover {
    transform: scale(0.99);
  }
  &__main-container {
    border-radius: var(--sem-radius-md);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  &__thumbnail-container {
    min-height: 240px;
    height: 240px;
  }
  &__content-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__author-name {
    color: var(--sem-color-primary-medium);
    font-weight: 500;
    line-height: 1;
  }
  &__posted {
    line-height: 1.25;

    font-size: 12px;
    font-weight: 500;
    color: var(--sem-color-neutral-medium);
  }
  &__heading {
    color: var(--sem-color-text-dark);
    margin-bottom: 4px;
    overflow: hidden;
    height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__preview {
    color: var(--sem-color-text-medium);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    margin-bottom: 8px;
    height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    display: -webkit-box;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-line-clamp: 2;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-box-orient: vertical;
  }
}
</style>
