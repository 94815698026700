<script>
import Heading from "../Heading/Heading.vue";
import Dropdown from "../Dropdown/Dropdown.vue";

export default {
  name: "FilterBar",
  components: {
    Heading,
    Dropdown
  },
  props: {
    headingText: {
      type: String,
      default: ""
    },
    filterList: {
      type: Array,
      default: () => []
    },
    rightFilterList: {
      type: Array,
      default: () => []
    },
    buttonType: {
      type: String,
      default: "primary-light"
    },
    buttonSize: {
      type: String,
      default: "small"
    }
  }
};
</script>

<template>
  <div class="suaw-filter-row">
    <div class="suaw-filter-row__actions">
      <div class="suaw-filter-row__left-filters">
        <Heading v-if="headingText" level="3" :content="headingText" />
        <slot name="filters" />
        <Dropdown
          v-for="(filter, index) in filterList"
          :key="index"
          :label="filter.label"
          :button-type="buttonType"
          :items="filter.dropdownItems"
          dropdown-type="combined"
          :dropdown-button-size="buttonSize"
          direction="left"
          @item-click="$emit('item-click', $event)"
        />
      </div>
      <div class="suaw-filter-row__right-filters">
        <Dropdown
          v-for="(filter, index) in rightFilterList"
          :key="index"
          :label="filter.label"
          :button-type="buttonType"
          :items="filter.dropdownItems"
          dropdown-type="combined"
          :dropdown-button-size="buttonSize"
          direction="right"
          @item-click="$emit('item-click', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-filter-row {
  width: 100%;
  &,
  &__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__actions {
    gap: var(--sem-space-base);
    width: 100%;
  }

  &__left-filters {
    display: flex;
    gap: var(--sem-space-base);
  }

  &__right-filters {
    display: flex;
    gap: var(--sem-space-base);
  }
}
</style>
