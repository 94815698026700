<script>
import Thumbnail from '../Thumbnail/Thumbnail.vue';
import Heading from '../Heading/Heading.vue';
import Paragraph from '../Paragraph/Paragraph.vue';
import Chip from '../Chip/Chip.vue';

export default {
  name: "CardChapter",
  components: {
    Thumbnail,
    Heading,
    Paragraph,
    Chip,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
    },
    heading: {
      type: String,
    },
    description: {
      type: Object,
    },
    members: {
      type: String,
    },
    location: {
      type: String,
    },
    chipItems: {
      type: Array,
      default: () => []
    },
  },
};
</script>

<template>
  <div 
    class="suaw-card__content"
    @click.stop="$emit('click', id)"
  >
    <Thumbnail
      size="large"
      :content="thumbnail"
      class="suaw-thumbnail--fit"
    />
    <Heading
      level="5"
      :content="heading"
    />
    <Paragraph
      v-if="description"
      weight="normal"
      size="small"
      :text="description"
    />
    <Chip
      class="location-chip"
      size="medium"
      :status="chipItems[0]?.status"
      :icon="chipItems[0]?.icon"
      :label="chipItems[0]?.label"
      chipType="icon"
      :pill="chipItems[0]?.pill"
    />
    <Chip
      size="medium"
      :status="chipItems[1]?.status"
      :icon="chipItems[1]?.icon"
      :label="chipItems[1]?.label"
      chipType="icon"
      :pill="chipItems[1]?.pill"
    />
    <Chip
      size="medium"
      :status="chipItems[2]?.status"
      :icon="chipItems[2]?.icon"
      :label="chipItems[2]?.label"
      chipType="icon"
      :pill="chipItems[2]?.pill"
    />
    <slot name="chips"></slot>
  </div>
</template>

<style lang="scss" scoped>
.suaw-card {
  &__content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    transition: transform .25s linear;

    :deep(.suaw-thumbnail--fit) {
      width: 100%;
    }

    :deep(.location-chip) {
      width: fit-content;
      max-width: var(--thumbnail-width, 100%);
      
      .suaw-chip__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
  }

  &__content:hover {
    cursor: pointer;
    transform: scale(0.99);
  }
}
</style>
