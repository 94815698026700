<script>
import Heading from "../Heading/Heading.vue";
import Button from "../Button/Button.vue";
import Chip from "../Chip/Chip.vue";
export default {
  name: "SeriesSummary",
  components: { Heading, Button, Chip },
  props: {
    title: {
      type: String,
      default: "Unknown Series Title"
    },
    isSubscribed: {
      type: Boolean,
      default: false
    },
    memberCount: {
      type: [Number]
    },
    isOrganizer: {
      type: Boolean,
      default: false
    },
    frequency: {
      type: String,
    },
  },
  computed: {
    seriesFrequency() {
      return `${this.frequency} Writing Group`;
    },
    seriesMembers() {
      return `${this.memberCount} Member${this.memberCount !== 1 ? "s" : ""}`;
    },
  },
};
</script>

<template>
  <div class="suaw-series-summary">
    <div class="suaw-series-summary__heading-container">
      <Heading level="2" :content="title" />
    </div>
    <div class="suaw-series-summary__action-container">
      <div v-if="isOrganizer" class="suaw-series-summary__button-container">
        <Button
          button-text="Edit Series"
          class="suaw-series-summary__action-button"
          size="large"
          type="primary-light"
          icon-left="IconRepeat"
          @click="$emit('edit-series-click')"
        />
      </div>
      <div class="suaw-series-summary__button-container">
        <Button
          :key="isSubscribed"
          :button-text="isSubscribed ? 'Subscribed' : 'Subscribe'"
          class="suaw-series-summary__action-button"
          size="large"
          :type="isSubscribed ? 'primary-outline' : 'primary'"
          :icon-left="isSubscribed ? 'IconDownloadDone' : ''"
          :icon-right="isSubscribed ? '' : 'IconCaretDown'"
          @click="$emit('subscribe-click')"
        />
      </div>
    </div>
    <div class="suaw-series-summary__info-container">
      <Chip size="medium" status="default" icon="IconCalendarCalendar" :label="seriesFrequency" chip-type="icon" />
      <Chip size="medium" status="info" icon="IconUser" :label="seriesMembers" chip-type="icon" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-series-summary {
  max-width: 1264px;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 24px;
  row-gap: 8px;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  &__action-container {
    display: inline-flex;
    justify-content: end;
    gap: 8px;
    @media (max-width: 768px) {
      grid-column: 2/3;
      grid-row: 1/3;
      flex-direction: column-reverse;
      justify-content: start;
      align-items: end;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: auto;
    }
  }
  &__button-container {
    @media (max-width: 768px) {
      justify-self: end;
    }
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__action-button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__info-container {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 8px;
    @media (max-width: 768px) {
      grid-column: 1/2;
      grid-row: 2/3;
      align-items: center;
    }
    @media (max-width: 650px) {
      grid-column: auto;
      grid-row: 2/3;
    }
  }
}
</style>
