<script>
import AvatarView from "./AvatarView.vue";

export default {
  name: "AvatarChipView",
  components: {
    AvatarView
  },
  props: {
    size: {
      type: String,
      default: "medium",
      validator: function(value) {
        return ["medium", "small"].indexOf(value) !== -1;
      }
    },
    color: {
      type: String,
      default: "default",
      validator(value) {
        return ["default", "online", "info", "success", "critical", "none"].indexOf(value) !== -1;
      }
    },
    avatarUrl: {
      type: String,
      default: ""
    },
    avatarColor: {
      type: String,
      default: ""
    },
    avatarInitials: {
      type: String,
      default: ""
    },
    isAvatarHidden: {
      type: Boolean,
      default: false
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
      default: ""
    },
    className: {
      type: String,
      default: ""
    }
  }
};
</script>

<template>
  <div :class="['suaw-chip', 'suaw-chip--' + size, 'suaw-chip--' + color]">
    <div class="suaw-chip__content suaw-chip__avatar-chip">
      <AvatarView
        size="small"
        :url="avatarUrl"
        :color="avatarColor"
        :display-name="displayName"
        :avatar-initials="avatarInitials"
        :is-avatar-hidden="isAvatarHidden"
        :is-deactivated="isDeactivated"
      />
      <span class="suaw-chip__text">{{ displayName }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chip {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 26px;
  border: 0.5px solid #dcdee9;
  border-radius: var(--sem-radius-lg, 16px);
  background: var(--sem-color-neutral-light);

  &__text {
    white-space: nowrap;
    margin-top: 1px;
    color: var(--sem-color-neutral-dark);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    .suaw-chip--small & {
      font-size: 12px;
    }

    // Ensure text color matches the state
    .suaw-chip--online & {
      color: var(--sem-color-complementary-medium); // Purple text color for online events
    }

    .suaw-chip--info & {
      color: var(--sem-color-info-medium);
    }

    .suaw-chip--success & {
      color: var(--sem-color-success-medium);
    }

    .suaw-chip--critical & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-chip--default & {
      color: var(--sem-color-text-medium);
    }
  }

  &__content {
    display: inline-flex;
    align-items: center;
  }

  &__avatar-chip {
    margin-right: 8px;
    gap: 4px;
  }

  // Styles for small size
  &--small {
    height: 19px;
  }

  // Background color styles
  &--default {
    background-color: var(--sem-color-neutral-light);
  }

  &--online {
    background-color: var(--sem-color-complementary-light); // Light purple background
  }

  &--info {
    background-color: var(--sem-color-info-light);
  }

  &--success {
    background-color: var(--sem-color-success-light);
  }

  &--critical {
    background-color: var(--sem-color-critical-light);
  }

  &--none {
    background-color: none;
  }
}
</style>
