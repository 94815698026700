<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Chip from "../Chip/Chip.vue";
import AvatarChipPipe from "../Avatar/AvatarChipPipe.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import TextArea from "../TextArea/TextArea.vue";
import Button from "../Button/Button.vue";
import EditorBox from "../EditorBox/EditorBox.vue";

export default {
  name: "ResponseItem",
  components: {
    Paragraph,
    Chip,
    AvatarChipPipe,
    Dropdown,
    TextArea,
    Button,
    EditorBox,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    parentPostId: {
      required: true,
      type: String,
    },
    headingText: {
      type: Object,
    },
    userId: {
      type: String,
      required: true
    },
    displayName: {
      type: String,
    },
    likeCount: {
      type: Number,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
    posted: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    showDiscussionLikes: {
      type: Boolean,
      default: true,
    },
    isSidepanelDiscussion: {
      type: Boolean,
    },
    textBoxPrimaryButton: {
      type: String,
    },
    textBoxSecondaryButton: {
      type: String,
    },
    textBoxSize: {
      type: String,
    },
    replyNumber: {
      type: Number,
      default: 0,
    },
    replyPlaceholder: {
      type: String,
      default: "Type your reply here...",
    },
    replyButtonText: {
      type: String,
      default: "Reply",
    },
  },
  watch: {
    headingText(newVal) {
      this.editableText = newVal;
    },
  },
  computed: {
    formattedReplyLabel() {
      return this.replyNumber === 0 ? 'No Replies' : `${this.replyNumber} Repl${this.replyNumber > 1 ? 'ies' : 'y'}`;
    },
    computedChipSize() {
      return this.isSidepanelDiscussion ? "small" : "medium";
    },
    computedDiscussionLikes() {
      if (!this.likeCount) {
        return "";
      }
      return `${this.likeCount}`;
    },
  },
  data() {
    return {
      editMode: false,
      editableText: this.headingText,
      hiddenText: false,
      replyEditMode: false,
      replyEditModes: [],
      hiddenList: false,
      showModal: false,
      buttonPressed: false,
      modalTitles: "Report Details",
      userInput: { type: "doc", content: [{ type: "paragraph" }] },
    };
  },
  methods: {
    handleChipClick() {
      this.hiddenList = !this.hiddenList;
    },
    handleInput(value) {
      this.userInput = value;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    handleEditorBoxPrimaryButtonClick() {
      const payload = {
        text: this.editableText,
        id: this.id,
        parentPostId: this.parentPostId,
      };
      this.$emit("edit-response", payload);
      this.toggleEditMode();
    },
    handleSubmit() {
      if (this.$refs.textArea.isEmpty()) {
        return;
      } else {
        const payload = {
          text: this.userInput,
          parentPostId: this.id,
        };
        this.$emit("submit-reply", payload);
        this.userInput = { type: "doc", content: [{ type: "paragraph" }] };
      }
    },
  },
};
</script>

<template>
  <div class="suaw-reply__content">
    <template v-if="editMode">
      <EditorBox
        v-model="editableText"
        :size="textBoxSize"
        :buttonText="textBoxPrimaryButton"
        :secondary-button-text="textBoxSecondaryButton"
        :use-tip-tap="true"
        @submit="handleEditorBoxPrimaryButtonClick"
        @secondary-click="toggleEditMode"
      />
    </template>

    <template v-else>
      <div 
        class="suaw-reply__text"
        @click="handleChipClick"
      >
        <Paragraph
          size="medium"
          weight="bold"
          :text="headingText"
        />
      </div>
    </template>

    <footer class="suaw-reply__footer">
      <div class="suaw-reply__block">
        <div class="suaw-reply__status-block">
          <AvatarChipPipe
            :user-id="userId"
            :size="computedChipSize"
          />
          <span
            :class="{
              'suaw-reply__posted--small': isSidepanelDiscussion,
              'suaw-reply__posted': !isSidepanelDiscussion
            }"
          >
            {{ posted }}
          </span>
        </div>

        <div class="suaw-reply__status-block">
          <Chip
            :size="computedChipSize"
            chipType="icon"
            status="info"
            icon="IconComment"
            shape="square"
            :label="formattedReplyLabel"
            class="suaw-chip--clickable"
            @click="handleChipClick"
          />
          <Chip
            v-if="showDiscussionLikes"
            :key="isLiked"
            :size="computedChipSize"
            chipType="icon"
            status="critical"
            :icon="isLiked ? 'IconHeartFill' : 'IconHeartOutline'"
            shape="square"
            class="suaw-chip--clickable"
            :label="computedDiscussionLikes"
            @click="$emit('like-click', { id, isLiked })"
          />
          <Dropdown
            iconDropdown="IconMoreHorizontal"
            dropdownButtonSize="small"
            buttonType="secondary-outline"
            buttonClassName="suaw-dropdown__button--chip"
            :items="dropdownItems"
            @editModeToggled="toggleEditMode"
            @item-click="$emit('dropdown-item-click', $event)"
          />
        </div>
      </div>

      <div
        v-if="hiddenList"
        class="suaw-reply__block--hidden"
      >
        <div class="suaw-reply__block--textarea-holder">
          <TextArea
            ref="textArea"
            v-model="userInput"
            :placeholder="replyPlaceholder"
            borderStyle="ghost"
            :use-tip-tap="true"
          />
          <Button
            size="small"
            type="primary-light"
            :buttonText="replyButtonText"
            @click="handleSubmit"
          />
        </div>
        <slot></slot>
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-reply {
  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__footer {
    flex-direction: column;
  }

  &__text {
    cursor: pointer;
  }

  &__footer--reply {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: space-between;
  }

  &__block--reply {
    display: flex;
    align-items: center;
  }

  &__block--textarea-holder {
    margin: var(--sem-space-double) 0;
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    flex-direction: column;
    gap: var(--sem-space-base);
    display: flex;
    align-items: flex-end;
    padding: var(--sem-space-base);
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__posted,
  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__block--hidden {
    flex-direction: column;
    position: relative;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
    list-style-type: none;
    padding: 0;
    width: 100%;
  }

  &__list-item {
    padding: var(--sem-space-triple);
    background-color: var(--sem-color-background-light);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    border-radius: var(--sem-radius-sm);
  }
}
</style>
