<script>
export default {
  name: "LoadingState"
};
</script>

<template>
  <div class="suaw-loading-state" />
</template>

<style lang="scss" scoped>
.suaw-loading-state {
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, var(--sem-color-info-light) 0%, var(--sem-color-background-light) 30%);
}
</style>
