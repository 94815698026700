<script>
import Icon from "../Icon/Icon.vue";
import Paragraph from "../Paragraph/Paragraph.vue";

export default {
  name: 'FileUpload',
  components: {
    Icon,
    Paragraph,
  },
  props: {
    uploadTitle: {
      type: String,
      default: 'Upload Chapter Image'
    },
    uploadMessage: {
      type: String,
      default: 'click to upload'
    },
  },
  data() {
    return {
      file: null,
      fileName: "Drop your file here or",
    };
  },
  methods: {
    handleDrop(event) {
      if (event.dataTransfer.items) {
        const file = event.dataTransfer.items[0].getAsFile();
        this.handleFile(file);
      }
    },
    openFileDialog() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.addEventListener("change", (event) => {
        const file = event.target.files[0];
        this.handleFile(file);
      });
      input.click();
    },
    handleFile(file) {
      this.file = file;
      this.fileName = file.name;
      this.$emit("file-selected", file);
    },
    allowDrop(event) {
      event.dataTransfer.dropEffect = "copy";
    },
  },
};
</script>

<template>
  <div
    class="suaw-file-upload"
    @drop.prevent="handleDrop"
    @dragover.prevent="allowDrop"
  >
    <a
      href="#"
      class="suaw-file-upload__link"
      @click.prevent="openFileDialog"
    ></a>
    <Icon
      icon="IconCloudUp"
      color="blue"
      class="suaw-file-upload__icon--large"
    />
    <Paragraph
      :text="uploadTitle"
      weight="bold"
      alignment="center"
    />
    <div class="suaw-file-upload__message">
      <Paragraph
        :text="fileName"
        size="small"
        alignment="center"
      />
      <Paragraph
        v-if="!file"
        :text="uploadMessage"
        size="small"
        alignment="center"
        class="suaw-file-upload__text--highlight"
      />
      <Icon
        v-else
        icon="IconEdit"
        color="blue"
        class="suaw-file-upload__icon--small"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.suaw-file-upload {
  border: 1px dashed var(--sem-color-info-medium);
  border-radius: var(--sem-radius-xs);
  padding: var(--sem-space-quadruple);
  overflow: hidden;
  position: relative;
  text-align: center;
  transition: border-color .25s linear;
  
  &:hover {
    border-color: var(--sem-color-info-dark);
  }

  &__message {
    align-items: center;
    display: flex;
    gap: var(--sem-space-half);
    justify-content: center;
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 95;
  }

  &__text--highlight {
    color: var(--sem-color-info-medium);

    .suaw-file-upload:hover & {
      color: var(--sem-color-info-dark);
    }
  }

  &__hover {
    opacity: .75;
  }

  &__icon--large {
    height: 32px;
    width: 32px;
  }

  &__icon--small {
    height: 14px;
    width: 14px;
  }
}
</style>
