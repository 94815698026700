import adminGuard from "@/utils/auth/adminGuard";

export default [
  {
    path: "/a/user-search",
    name: "UserSearch",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/userSearch/UserSearch.vue")
  },
  {
    path: "/a/user/:userId",
    name: "UserView",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/userView/UserView.vue")
  },
  {
    path: "/a/unresolved-complaints",
    name: "UnresolvedComplaints",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/userComplaints/UnresolvedComplaints.vue")
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import("@/screens/authLogin/LogIn.vue"),
    props: route => ({
      reason: route.params.reason
    }),
    alias: ["/sign-in"]
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import("@/screens/authSignup/SignUp.vue"),
    props: route => ({
      reason: route.params.reason
    })
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/features/users/auth/views/ResetPassword.vue")
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordToken",
    component: () => import("@/features/users/auth/views/ResetPassword.vue")
  },
  {
    path: "/complete-account",
    name: "CompleteAccount",
    component: () => import("@/features/users/auth/views/CompleteAccount.vue")
  },
  {
    path: "/complete-account/:token",
    name: "CompleteAccountToken",
    component: () => import("@/features/users/auth/views/CompleteAccount.vue")
  },
  {
    path: "/users/:userId",
    name: "UserProfile",
    component: () => import("@/features/users/dashboard/views/DashboardProfileView.vue"),
    props: { isDashboard: false }
  }
];
