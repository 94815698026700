<script>
/* global google */
import Icon from "../Icon/Icon.vue";
import Button from "../Button/Button.vue";
export default {
  name: "SearchInput",
  components: {
    Icon,
    Button
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    useGoogle: {
      type: Boolean,
      default: false,
    },
    citiesOnly: {
      type: Boolean,
      default: false,
    },
    searchLimit: {
      type: Number,
      default: 5,
    },
    debounceTime: {
      type: Number,
      default: 300,
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Search...",
    },
    className: {
      type: String,
      default: "",
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: "searchInput",
    },
    errorMessage: {
      type: String,
      default: "This field is required",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      validator: function (value) {
        return [
          "valid",
          "invalid",
          "disabled",
          "default",
        ].indexOf(value) !== -1;
      },
    },
    borderStyle: {
      type: String,
      default: "default",
      validator: function (value) {
        return [
          "default",
          "ghost",
        ].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      searchTerm: this.value,
      results: [],
      service: null,
      debounceTimer: null,
    };
  },
  watch: {
    searchResults(newResults) {
      if (!this.useGoogle) {
        this.results = newResults;
      }
    },
  },
  computed: {
    filteredResults() {
      return this.results.slice(0, this.searchLimit);
    },
    inputClasses() {
      return {
        'suaw-search-input--valid': this.state === 'valid',
        'suaw-search-input--invalid': this.state === 'invalid',
        'suaw-search-input--disabled': this.state === 'disabled',
        'suaw-search-input--required': this.isRequired,
        'suaw-search-input--icon': this.showIcon || this.type !== 'text'
      };
    },
    setMessages() {
      if (this.state === "invalid") return this.errorMessage;
      return "";
    },
    messageClasses() {
      return {
        'suaw-search-input__message--invalid': this.state === 'invalid',
        'suaw-search-input__message--valid': this.state === 'valid',
      };
    }
  },
  mounted() {
    if (this.useGoogle) {
      this.initAutocomplete();
    }
  },
  methods: {
    initAutocomplete() {
      if (!window.google) {
        console.error("Google Maps API not loaded");
        return;
      }
      this.service = new google.maps.places.AutocompleteService();
    },
    handleInput(value) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.searchTerm = value;
        this.$emit("input", this.searchTerm);
        if (!this.searchTerm) {
          this.results = [];
        } else {
          if (this.useGoogle) {
            this.fetchGooglePredictions();
          } else {
            let filtered = this.searchResults.filter((item) =>
              item.text.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
            if (filtered.length === 0) {
              this.results = [
                { text: "No results found", value: null, disabled: true },
              ];
            } else {
              this.results = filtered;
            }
          }
        }
      }, this.debounceTime);
    },
    onItemSelected(item) {
      this.searchTerm = item.text;
      this.$emit("input", item.text);
      if (this.useGoogle) {
        const div = document.createElement("div");
        const placeService = new google.maps.places.PlacesService(div);
        placeService.getDetails(
          {
            placeId: item.value,
            fields: [
              "place_id",
              "name",
              "types",
              "formatted_address",
              "geometry",
              "address_components",
            ],
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              this.$emit("item-select", place);
            }
          }
        );
      } else {
        this.$emit("item-select", item);
      }
      if (this.clearOnSelect) {
        this.searchTerm = "";
      }
      this.results = [];
    },
    fetchGooglePredictions() {
      if (!this.searchTerm) {
        this.results = [];
        return;
      }
      const options = {
        input: this.searchTerm,
        types: this.citiesOnly ? ["(cities)"] : [],
      };
      this.service.getPlacePredictions(options, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.results = predictions.map((pred) => ({
            text: pred.description,
            value: pred.place_id,
          }));
        } else {
          this.results = [];
        }
      });
    },
  },
};
</script>

<template>
  <div
    class="suaw-search-input"
    :class="[inputClasses, { [className]: className }]"
  >
    <Label
      :labelFor="id"
      size="small"
    >{{ label }}</Label>
    <div class="suaw-search-input__holder">
      <input
        class="suaw-search-input__field"
        v-model="searchTerm"
        :class="['suaw-search-input__field--' + borderStyle]"
        :placeholder="placeholder"
        @input="handleInput($event.target.value)"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
      >
      <Icon
        v-if="showIcon"
        icon="IconSearch"
        class="suaw-search-input__icon"
        :key="showIcon.toString()"
        color="gray"
      />
    </div>
    <div
      v-if="results.length > 0"
      class="suaw-search-input__dropdown"
    >
      <div class="suaw-search-input__box">
        <ul class="suaw-search-input__list">
          <li
            v-for="(item, index) in filteredResults"
            :key="item.id || index"
            class="suaw-search-input__item"
          >
            <Button
              class="suaw-search-input__link"
              :buttonText="item.text"
              type="secondary-ghost"
              @click="!item.disabled && onItemSelected(item)"
            />
          </li>
        </ul>
      </div>
    </div>
    <small
      v-if="isRequired"
      class="suaw-search-input__message"
      :class="messageClasses"
    >{{ setMessages }}</small>
  </div>
</template>

<style lang="scss" scoped>
.suaw-search-input {

  &__holder {
    position: relative;
  }

  &__field {
    font-family: var(--base-font-family);
    font-size: var(--button-normal-font-size);
    font-weight: var(--button-normal-font-weight);
    height: 40px;
    padding: var(--sem-space-base) var(--sem-space-double);
    transition: border-color, .25s linear;
    width: 100%;

    .suaw-search-input--icon & {
      padding-left: var(--sem-space-quadruple);
    }
  }

  &__field:not(.suaw-search-input__field--ghost) {
    border: 1px solid var(--sem-color-border-regular);
    border-radius: var(--sem-radius-sm);
  }

  &__field:not(.suaw-search-input__field--ghost):hover {
    border-color: var(--sem-color-border-medium);
  }

  &__field--ghost {
    border: 0;
    font-weight: bold;
    color: var(--sem-color-neutral-medium);
  }

  &__field:focus,
  &__field:focus-visible {
    border-color: var(--sem-color-border-darkest);
    outline: 0;
  }

  &__field:not(:focus) {
    box-shadow: none;

    .suaw-search-input--invalid & {
      border-color: var(--sem-color-critical-medium);
    }
  }

  &__icon {
    left: var(--sem-space-base);
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__message {
    color: var(--sem-color-critical-medium);
    margin-top: var(--sem-space-base);
  }

  &__dropdown {
    display: block;
    position: relative;
  }

  &__box {
    background: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    box-shadow: var(--soft-shadow-draft);
    padding: var(--sem-space-double);
    position: absolute;
    right: 0;
    max-height: 432px;
    min-width: 200px;
    overflow: auto;
    transition-property: opacity, visibility;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    width: 100%;
    z-index: 97;
  }

  &__list {
    padding-left: var(--sem-space-none);
    padding-right: var(--sem-space-none);
    list-style: none;
  }

  &__list:not(:first-child) {
    padding-top: var(--sem-space-double);
  }

  &__list:not(:last-child) {
    border-bottom: 1px solid var(--sem-color-border-medium);
    padding-bottom: var(--sem-space-double);
  }

  &__link {
    border-radius: var(--sem-radius-md);
    color: var(--sem-color-text-regular);
    display: flex;
    align-items: center;
    font-size: var(--body-copy-font-size);
    padding: var(--sem-space-base);
    text-decoration: none;
    transition-property: background-color, color;
    transition-duration: .25s;
    transition-timing-function: linear;
    justify-content: flex-start;
    width: 100%;
  }

  &__link:hover {
    background-color: var(--sem-color-background-light);
    color: var(--sem-color-text-darkest);
  }

  &__icon,
  &__label {
    margin-right: var(--sem-space-base);
  }
}
</style>
