import adminGuard from "@/utils/auth/adminGuard";

export default [
  {
    path: "/a/chapter-create",
    name: "ChapterCreate",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/chapterManage/ChapterManage.vue")
  },
  {
    path: "/a/chapter-manage/:chapterId",
    name: "ChapterManage",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/chapterManage/ChapterManage.vue")
  },
  {
    path: "/a/chapter-search",
    name: "ChapterSearch",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/chapterSearch/ChapterSearch.vue")
  },
  {
    path: "/a/chapter/:chapterId",
    name: "ChapterView",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/chapterView/ChapterView.vue")
  },
  {
    path: "/chapter/:chapterId/:chapterSlug",
    name: "Chapter",
    component: () => import("@/screens/chapterDetail/Chapter.vue")
  },
  {
    path: "/chapters",
    name: "ChapterDiscovery",
    component: () => import("@/screens/chapterDiscovery/ChapterDiscovery.vue")
  }
];
