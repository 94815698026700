import homeGuard from "@/utils/auth/homeGuard";

export default [
  {
    path: "/",
    name: "Home",
    beforeEnter: homeGuard,
    component: () => import("@/screens/home/Home.vue")
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: () => import("@/screens/home/Maintenance.vue")
  }
  //NotFound is in router/index.js
];
