export default [
  {
    path: "/resources/articles",
    name: "ArticleDiscovery",
    component: () => import("@/screens/resourcesArticles/ArticleDiscovery.vue"),
    alias: ["/articles"]
  },
  {
    path: "/resources/articles/beginning-tips-for-writers-the-basics",
    name: "articles-beginning-tips-for-writers-the-basics",
    component: () => import("@/screens/resourcesArticles/20160213_BeginningTipsForWritersTheBasics.vue"),
    alias: ["/beginning-tips-for-writers-the-basics", "/articles/beginning-tips-for-writers-the-basics"]
  },
  {
    path: "/resources/articles/conquer-procrastination-and-get-writing",
    name: "articles-conquer-procrastination-and-get-writing",
    component: () => import("@/screens/resourcesArticles/20160403_ConquerProcrastinationAndGetWriting.vue"),
    alias: ["/conquer-procrastination-and-get-writing", "/articles/conquer-procrastination-and-get-writing"]
  },
  {
    path: "/resources/articles/perfectionism-distracts-you-from-writing-perfectly",
    name: "articles-perfectionism-distracts-you-from-writing-perfectly",
    component: () => import("@/screens/resourcesArticles/20160405_PerfectionismDistractsYouFromWritingPerfectly.vue"),
    alias: ["/perfectionism-distracts-you-from-writing-perfectly", "/articles/perfectionism-distracts-you-from-writing-perfectly"]
  },
  {
    path: "/resources/articles/beginning-tips-for-writers-working-styles-and-wip",
    name: "articles-beginning-tips-for-writers-working-styles-and-wip",
    component: () => import("@/screens/resourcesArticles/20160416_BeginningTipsForWritersWorkingStylesAndWip.vue"),
    alias: ["/beginning-tips-for-writers-working-styles-and-wip", "/articles/beginning-tips-for-writers-working-styles-and-wip"]
  },
  {
    path: "/resources/articles/after-the-first-draft-pro-tips-for-editing-your-work",
    name: "articles-after-the-first-draft-pro-tips-for-editing-your-work",
    component: () => import("@/screens/resourcesArticles/20160518_AfterTheFirstDraftProTipsForEditingYourWork.vue"),
    alias: ["/after-the-first-draft-pro-tips-for-editing-your-work", "/articles/after-the-first-draft-pro-tips-for-editing-your-work"]
  },
  {
    path: "/resources/articles/five-geeky-hobbies-for-writers-that-arent-reading",
    name: "articles-five-geeky-hobbies-for-writers-that-arent-reading",
    component: () => import("@/screens/resourcesArticles/20170110_FiveGeekyHobbiesForWritersThatArentReading.vue"),
    alias: ["/five-geeky-hobbies-for-writers-that-arent-reading", "/articles/five-geeky-hobbies-for-writers-that-arent-reading"]
  },
  {
    path: "/resources/articles/attending-the-muse-or-your-writing-discipline",
    name: "articles-attending-the-muse-or-your-writing-discipline",
    component: () => import("@/screens/resourcesArticles/20170228_AttendingTheMuseOrYourWritingDiscipline.vue"),
    alias: ["/attending-the-muse-or-your-writing-discipline", "/articles/attending-the-muse-or-your-writing-discipline"]
  },
  {
    path: "/resources/articles/its-okay-to-be-edited-really",
    name: "articles-its-okay-to-be-edited-really",
    component: () => import("@/screens/resourcesArticles/20170901_ItsOkayToBeEditedReally.vue"),
    alias: ["/its-okay-to-be-edited-really", "/articles/its-okay-to-be-edited-really"]
  },
  {
    path: "/resources/articles/5-simple-habits-to-boost-your-writing",
    name: "articles-5-simple-habits-to-boost-your-writing",
    component: () => import("@/screens/resourcesArticles/20180204_FiveSimpleHabitsToBoostYourWriting.vue"),
    alias: ["/5-simple-habits-to-boost-your-writing", "/articles/5-simple-habits-to-boost-your-writing"]
  },
  {
    path: "/resources/articles/4-easy-steps-to-a-writing-plan",
    name: "articles-4-easy-steps-to-a-writing-plan",
    component: () => import("@/screens/resourcesArticles/20180412_FourEasyStepsToAWritingPlan.vue"),
    alias: ["/4-easy-steps-to-a-writing-plan", "/articles/4-easy-steps-to-a-writing-plan"]
  },
  {
    path: "/resources/articles/shut-up-and-writes-nanowrimo-survival-guide",
    name: "articles-shut-up-and-writes-nanowrimo-survival-guide",
    component: () => import("@/screens/resourcesArticles/20191101_ShutUpAndWritesNanowrimoSurvivalGuide.vue"),
    alias: ["/shut-up-and-writes-nanowrimo-survival-guide", "/articles/shut-up-and-writes-nanowrimo-survival-guide"]
  },
  {
    path: "/resources/articles/five-ways-to-silence-your-inner-critic-while-drafting",
    name: "articles-five-ways-to-silence-your-inner-critic-while-drafting",
    component: () => import("@/screens/resourcesArticles/20191201_FiveWaysToSilenceYourInnerCriticWhileDrafting.vue"),
    alias: ["/five-ways-to-silence-your-inner-critic-while-drafting", "/articles/five-ways-to-silence-your-inner-critic-while-drafting"]
  },
  {
    path: "/resources/articles/creating-your-writing-environment",
    name: "articles-creating-your-writing-environment",
    component: () => import("@/screens/resourcesArticles/20200129_CreatingYourWritingEnvironment.vue"),
    alias: ["/creating-your-writing-environment", "/articles/creating-your-writing-environment"]
  },
  {
    path: "/resources/articles/the-writers-guide-to-editors",
    name: "articles-the-writers-guide-to-editors",
    component: () => import("@/screens/resourcesArticles/20200221_TheWritersGuideToEditors.vue"),
    alias: ["/the-writers-guide-to-editors", "/articles/the-writers-guide-to-editors"]
  },
  {
    path: "/resources/articles/10-best-practices-for-writing-from-home",
    name: "articles-10-best-practices-for-writing-from-home",
    component: () => import("@/screens/resourcesArticles/20200320_TenBestPracticesForWritingFromHome.vue"),
    alias: ["/10-best-practices-for-writing-from-home", "/articles/10-best-practices-for-writing-from-home"]
  },
  {
    path: "/resources/articles/the-writers-guide-to-copyright",
    name: "articles-the-writers-guide-to-copyright",
    component: () => import("@/screens/resourcesArticles/20200406_TheWritersGuideToCopyright.vue"),
    alias: ["/the-writers-guide-to-copyright", "/articles/the-writers-guide-to-copyright"]
  },
  {
    path: "/resources/articles/seven-strategies-to-get-you-writing-again",
    name: "articles-seven-strategies-to-get-you-writing-again",
    component: () => import("@/screens/resourcesArticles/20200526_SevenStrategiesToGetYouWritingAgain.vue"),
    alias: ["/seven-strategies-to-get-you-writing-again", "/articles/seven-strategies-to-get-you-writing-again"]
  },
  {
    path: "/resources/articles/four-essential-steps-to-developing-your-writing-practice",
    name: "articles-four-essential-steps-to-developing-your-writing-practice",
    component: () => import("@/screens/resourcesArticles/20200527_FourEssentialStepsToDevelopingYourWritingPractice.vue"),
    alias: ["/four-essential-steps-to-developing-your-writing-practice", "/articles/four-essential-steps-to-developing-your-writing-practice"]
  },
  {
    path: "/resources/articles/how-to-get-feedback-on-your-writing",
    name: "articles-how-to-get-feedback-on-your-writing",
    component: () => import("@/screens/resourcesArticles/20200706_HowToGetFeedbackOnYourWriting.vue"),
    alias: ["/how-to-get-feedback-on-your-writing", "/articles/how-to-get-feedback-on-your-writing"]
  },
  {
    path: "/resources/articles/tricks-for-writing-under-pressure",
    name: "articles-tricks-for-writing-under-pressure",
    component: () => import("@/screens/resourcesArticles/20200715_TricksForWritingUnderPressure.vue"),
    alias: ["/tricks-for-writing-under-pressure", "/articles/tricks-for-writing-under-pressure"]
  },
  {
    path: "/resources/articles/how-to-get-a-book-cover-youre-proud-of",
    name: "articles-how-to-get-a-book-cover-youre-proud-of",
    component: () => import("@/screens/resourcesArticles/20200814_HowToGetABookCoverYoureProudOf.vue"),
    alias: ["/how-to-get-a-book-cover-youre-proud-of", "/articles/how-to-get-a-book-cover-youre-proud-of"]
  },
  {
    path: "/resources/articles/finding-and-working-with-a-beta-reader",
    name: "articles-finding-and-working-with-a-beta-reader",
    component: () => import("@/screens/resourcesArticles/20200818_FindingAndWorkingWithABetaReader.vue"),
    alias: ["/finding-and-working-with-a-beta-reader", "/articles/finding-and-working-with-a-beta-reader"]
  },
  {
    path: "/resources/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    name: "articles-how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write",
    component: () => import("@/screens/resourcesArticles/20200821_HowICompletedMyFirstNovelWithTheHelpOfShutUpAndWrite.vue"),
    alias: ["/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write", "/articles/how-i-completed-my-first-novel-with-the-help-of-shut-up-and-write"]
  },
  {
    path: "/resources/articles/how-to-edit-the-first-draft-of-your-novel",
    name: "articles-how-to-edit-the-first-draft-of-your-novel",
    component: () => import("@/screens/resourcesArticles/20200921_HowToEditTheFirstDraftOfYourNovel.vue"),
    alias: ["/how-to-edit-the-first-draft-of-your-novel", "/articles/how-to-edit-the-first-draft-of-your-novel"]
  },
  {
    path: "/resources/articles/between-editing-and-submission",
    name: "articles-between-editing-and-submission",
    component: () => import("@/screens/resourcesArticles/20200929_BetweenEditingAndSubmission.vue"),
    alias: ["/between-editing-and-submission", "/articles/between-editing-and-submission"]
  },
  {
    path: "/resources/articles/how-to-find-a-literary-agent",
    name: "articles-how-to-find-a-literary-agent",
    component: () => import("@/screens/resourcesArticles/20201026_HowToFindALiteraryAgent.vue"),
    alias: ["/how-to-find-a-literary-agent", "/articles/how-to-find-a-literary-agent"]
  },
  {
    path: "/resources/articles/make-writing-a-habit-not-a-choice",
    name: "articles-make-writing-a-habit-not-a-choice",
    component: () => import("@/screens/resourcesArticles/20201118_MakeWritingAHabitNotAChoice.vue"),
    alias: ["/make-writing-a-habit-not-a-choice", "/articles/make-writing-a-habit-not-a-choice"]
  },
  {
    path: "/resources/articles/staying-creative-while-the-world-is-burning",
    name: "articles-staying-creative-while-the-world-is-burning",
    component: () => import("@/screens/resourcesArticles/20201118_StayingCreativeWhileTheWorldIsBurning.vue"),
    alias: ["/staying-creative-while-the-world-is-burning", "/articles/staying-creative-while-the-world-is-burning"]
  },
  {
    path: "/resources/articles/how-to-self-edit-in-six-painless-steps",
    name: "articles-how-to-self-edit-in-six-painless-steps",
    component: () => import("@/screens/resourcesArticles/20201120_HowToSelfEditInSixPainlessSteps.vue"),
    alias: ["/how-to-self-edit-in-six-painless-steps", "/articles/how-to-self-edit-in-six-painless-steps"]
  },
  {
    path: "/resources/articles/should-you-self-publish-or-traditionally-publish",
    name: "articles-should-you-self-publish-or-traditionally-publish",
    component: () => import("@/screens/resourcesArticles/20201208_ShouldYouSelfPublishOrTraditionallyPublish.vue"),
    alias: ["/should-you-self-publish-or-traditionally-publish", "/articles/should-you-self-publish-or-traditionally-publish"]
  },
  {
    path: "/resources/articles/how-to-work-with-an-illustrator",
    name: "articles-how-to-work-with-an-illustrator",
    component: () => import("@/screens/resourcesArticles/20201216_HowToWorkWithAnIllustrator.vue"),
    alias: ["/how-to-work-with-an-illustrator", "/articles/how-to-work-with-an-illustrator"]
  },
  {
    path: "/resources/articles/how-to-keep-your-writing-on-track",
    name: "articles-how-to-keep-your-writing-on-track",
    component: () => import("@/screens/resourcesArticles/20210113_HowToKeepYourWritingOnTrack.vue"),
    alias: ["/how-to-keep-your-writing-on-track", "/articles/how-to-keep-your-writing-on-track"]
  },
  {
    path: "/resources/articles/the-elements-of-storytelling-for-novelists",
    name: "articles-the-elements-of-storytelling-for-novelists",
    component: () => import("@/screens/resourcesArticles/20210119_TheElementsOfStorytellingForNovelists.vue"),
    alias: ["/the-elements-of-storytelling-for-novelists", "/articles/the-elements-of-storytelling-for-novelists"]
  },
  {
    path: "/resources/articles/five-steps-to-improve-pacing-in-your-story",
    name: "articles-five-steps-to-improve-pacing-in-your-story",
    component: () => import("@/screens/resourcesArticles/20210211_FiveStepsToImprovePacingInYourStory.vue"),
    alias: ["/five-steps-to-improve-pacing-in-your-story", "/articles/five-steps-to-improve-pacing-in-your-story"]
  },
  {
    path: "/resources/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds",
    name: "articles-submissions-the-harsh-reality-and-how-to-improve-your-odds",
    component: () => import("@/screens/resourcesArticles/20210603_SubmissionsTheHarshRealityAndHowToImproveYourOdds.vue"),
    alias: ["/submissions-the-harsh-reality-and-how-to-improve-your-odds", "/articles/submissions-the-harsh-reality-and-how-to-improve-your-odds"]
  },
  {
    path: "/resources/articles/five-ways-to-create-new-settings-in-your-writing",
    name: "articles-five-ways-to-create-new-settings-in-your-writing",
    component: () => import("@/screens/resourcesArticles/20210628_FiveWaysToCreateNewSettingsInYourWriting.vue"),
    alias: ["/five-ways-to-create-new-settings-in-your-writing", "/articles/five-ways-to-create-new-settings-in-your-writing"]
  },
  {
    path: "/resources/articles/five-traits-of-an-unforgettable-scene",
    name: "articles-five-traits-of-an-unforgettable-scene",
    component: () => import("@/screens/resourcesArticles/20210717_FiveTraitsOfAnUnforgettableScene.vue"),
    alias: ["/five-traits-of-an-unforgettable-scene", "/articles/five-traits-of-an-unforgettable-scene"]
  },
  {
    path: "/resources/articles/when-should-i-call-myself-a-writer",
    name: "articles-when-should-i-call-myself-a-writer",
    component: () => import("@/screens/resourcesArticles/20230217_WhenShouldICallMyselfAWriter.vue"),
    alias: ["/when-should-i-call-myself-a-writer", "/articles/when-should-i-call-myself-a-writer"]
  },
  {
    path: "/resources/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    name: "articles-what-should-i-write-about-five-ways-to-find-new-writing-ideas",
    component: () => import("@/screens/resourcesArticles/20230127_WhatShouldIWriteAboutFiveWaysToFindNewWritingIdeas.vue"),
    alias: ["/what-should-i-write-about-five-ways-to-find-new-writing-ideas", "/articles/what-should-i-write-about-five-ways-to-find-new-writing-ideas"]
  },
  {
    path: "/resources/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    name: "articles-i-wrote-1000-words-for-30-days-and-here-is-what-happened",
    component: () => import("@/screens/resourcesArticles/20230321_IWrote1000WordsFor30DaysAndHereIsWhatHappened.vue"),
    alias: ["/i-wrote-1000-words-for-30-days-and-here-is-what-happened", "/articles/i-wrote-1000-words-for-30-days-and-here-is-what-happened"]
  },
  {
    path: "/resources/articles/how-to-make-friends-with-chat-gpt",
    name: "articles-how-to-make-friends-with-chat-gpt",
    component: () => import("@/screens/resourcesArticles/20230501_HowToMakeFriendsWithChatGpt.vue"),
    alias: ["/how-to-make-friends-with-chat-gpt", "/articles/how-to-make-friends-with-chat-gpt"]
  }
];
