import Vue from "vue";
import VueRouter from "vue-router";
import { setLoading } from "@/utils/plugins/loadingState";
import aboutRoutes from "./aboutRoutes";
import challengeRoutes from "./challengeRoutes";
import chapterRoutes from "./chapterRoutes";
import userRoutes from "./userRoutes";
import resourcesArticleRoutes from "./resourcesArticleRoutes";
import resourcesFooterRoutes from "./resourcesFooterRoutes";
import resourcesGeneralRoutes from "./resourcesGeneralRoutes";
import resourcesIndexRoutes from "./resourcesIndexRoutes";
import resourcesOrganizerRoutes from "./resourcesOrganizerRoutes";
import homeRoutes from "./homeRoutes";
import adminRoutes from "./adminRoutes";
import seriesRoutes from "./seriesRoutes";

import authGuard from "../utils/auth/authGuard";
import accountFinishedCheck from "../utils/auth/accountFinishedCheck";
import provisionalLoginCheck from "../utils/auth/provisionalLoginCheck";
import seriesLookup from "../utils/auth/seriesLookup";
import eventLookup from "../utils/auth/eventLookup";
//import maintenanceCheck from "../utils/auth/maintenanceCheck";
import eventOrganizerGuard from "../utils/auth/eventOrganizerGuard";
//import adminGuard from "../utils/auth/adminGuard";

Vue.use(VueRouter);

const routes = [
  ...aboutRoutes,
  ...challengeRoutes,
  ...chapterRoutes,
  ...userRoutes,
  ...resourcesArticleRoutes,
  ...resourcesFooterRoutes,
  ...resourcesGeneralRoutes,
  ...resourcesIndexRoutes,
  ...resourcesOrganizerRoutes,
  ...homeRoutes,
  ...adminRoutes,
  ...seriesRoutes,
  //dashboard
  {
    path: "/dashboard/",
    name: "DashboardEvents",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardEvents.vue")
  },
  {
    path: "/dashboard/profile",
    name: "DashboardProfileView",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardProfileView.vue"),
    props: { isDashboard: true }
  },
  {
    path: "/dashboard/profile/edit",
    name: "DashboardProfileEdit",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardProfileEdit.vue")
  },
  {
    path: "/dashboard/mail",
    name: "DashboardMail",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardMail.vue")
  },
  {
    path: "/dashboard/settings",
    name: "DashboardSettingsView",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsView.vue")
  },
  {
    path: "/dashboard/settings/view/:token",
    name: "DashboardSettingsViewToken",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsView.vue")
  },
  {
    path: "/dashboard/settings/edit",
    name: "DashboardSettingsEdit",
    beforeEnter: authGuard,
    component: () => import("../features/users/dashboard/views/DashboardSettingsEdit.vue")
  },
  {
    path: "/series/:seriesId/event-edit/:eventId",
    name: "EventEdit",
    beforeEnter: eventOrganizerGuard,
    component: () => import("../features/events/edit/views/EventEdit.vue")
  },
  {
    path: "/online-events",
    name: "OnlineEventDiscovery",
    component: () => import("../features/events/discovery/views/OnlineEventDiscovery.vue"),
    props: route => ({
      selectedDate: route.query.selectedDate || null
    })
  },
  //legacy event routes that are still needed
  {
    path: "/events/:slug-writing-group/:event_id",
    name: "event_detail",
    beforeEnter: eventLookup
  },
  {
    path: "/events/:slug/:event_id",
    name: "event_detail_non_seo",
    beforeEnter: eventLookup
  },  
  //legacy series routes that are still needed
  {
    path: "/series-lookup/:event_template_id",
    name: "legacy_series_lookup",
    beforeEnter: seriesLookup
  },
  {
    path: "/series/:slug/:event_template_id",
    name: "legacy_series",
    beforeEnter: seriesLookup
  },
  //series admin
  {
    path: "/a/series/:seriesId",
    name: "SeriesView",
    component: () => import("../features/series/admin/views/SeriesView.vue")
  },
  //Prototyping/testing
  // {
  //   path: "/apiTest",
  //   name: "ApiTest",
  //   beforeEnter: adminGuard,
  //   component: () => import("../features/prototype/ApiTest.vue")
  // },
  // {
  //   path: "/tiptaptest",
  //   name: "TipTapTest",
  //   beforeEnter: adminGuard,
  //   component: () => import("../features/prototype/TipTapTest.vue")
  // },
  //To be safe, leave NotFound out of homeRoutes to ensure it's always last in the router
  {
    path: "*",
    name: "404",
    component: () => import("@/screens/home/NotFoundPage.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  }
});

router.beforeEach(accountFinishedCheck);
router.beforeEach(provisionalLoginCheck);
//router.beforeEach(maintenanceCheck); //comment this line out (and deploy) when we aren't in maintenance mode

router.beforeEach(async (to, from, next) => {
  setLoading(true);
  next();
});

router.afterEach(() => {
  setLoading(false);
});

export default router;
