<script>
import LikeButtonView from "./LikeButtonView.vue";
import * as DiscussionApi from "@/features/discussions/api.js";
import { GetPostLikes } from "./likeButtonOperations.gql";

export default {
  name: "LikeButtonPipe",
  components: {
    LikeButtonView
  },
  props: {
    postId: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: "medium"
    }
  },
  data() {
    return {
      loadingCount: 0,
      resultGetPostLikes: null,
      localLikeCount: 0,
      localIsLiked: false
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    isAuthenticated() {
      return this.$auth && this.$auth.isAuthenticated;
    },
    userId() {
      return this.isAuthenticated ? this.$auth.user.id : null;
    },
    isLiked() {
      return this.localIsLiked;
    },
    likeCount() {
      return this.localLikeCount;
    }
  },
  apollo: {
    resultGetPostLikes: {
      query: GetPostLikes,
      variables() {
        return {
          postId: this.postId,
          userId: this.userId
        };
      },
      result({ data }) {
        if (data.resultGetPostLikes) {
          this.localLikeCount = data.resultGetPostLikes.reactions_aggregate.aggregate.count || 0;
          this.localIsLiked = (data.resultGetPostLikes.reactions || []).length > 0;
        }
      },
      skip() {
        return !this.userId;
      },
      loadingKey: "loadingCount"
    }
  },
  methods: {
    async handleLikeClick() {
      if (!this.isAuthenticated) return;

      const result = this.localIsLiked 
        ? await DiscussionApi.unstampPost(this.postId, this.userId, "Like") 
        : await DiscussionApi.stampPost(this.postId, this.userId, "Like");

      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }

      // Update local data
      this.localLikeCount += this.localIsLiked ? -1 : 1;
      this.localIsLiked = !this.localIsLiked;
    }
  }
};
</script>

<template>
  <LikeButtonView v-if="!isLoading" :size="size" :like-count="likeCount" :is-liked="isLiked" @click="handleLikeClick" />
  <div v-else></div>
</template>
