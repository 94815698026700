<script>
import Heading from "../Heading/Heading.vue";

export default {
  name: "PostReplyListView",
  components: {
    PostPipe: () => import("./PostPipe.vue"),
    Heading,
  },
  props: {
    replyPosts: {
      type: Array,
      default: () => [],
      validator: (value) => value.every(item => 
        typeof item === 'object' && 
        typeof item.id === 'string' && 
        typeof item.parentPostId === 'string'
      )
    },
    isAtMaxDepth: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formattedResponseLabel() {
      const count = this.replyPosts.length;
      return count === 0 ? "No Responses" : `${count} Response${count > 1 ? "s" : ""}`;
    }
  }
};
</script>

<template>
  <div class="suaw-discussion">
    <Heading
      v-if="!isAtMaxDepth"
      :level="5"
      :content="formattedResponseLabel"
    />
    <ul :class="{
      'suaw-discussion-list': !isAtMaxDepth,
      'suaw-reply__list': isAtMaxDepth
    }">
      <li
        v-for="(post, index) in replyPosts"
        :key="index"
        :class="{
          'suaw-discussion-list__item': !isAtMaxDepth,
          'suaw-reply__list-item': isAtMaxDepth
        }"
      >
        <PostPipe :post-id="post.id" :parent-post-id="post.parentPostId" />
      </li>
      <slot></slot>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.suaw-discussion {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);

  &-list {
    border-radius: var(--sem-radius-sm);
    list-style-type: none;
    padding: var(--sem-space-base) 0 0;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);

    &__item {
      background-color: var(--sem-color-background-lightest);
      box-shadow: var(--soft-shadow-draft);
      border-radius: var(--sem-radius-sm);
      display: var(--comp-card-display);
      flex-direction: column;
      padding: var(--sem-space-triple);
    }
  }
}

.suaw-reply {
  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
    list-style-type: none;
    padding: 0;
    width: 100%;

    &-item {
      padding: var(--sem-space-triple);
      background-color: var(--sem-color-background-light);
      display: flex;
      flex-direction: column;
      gap: var(--sem-space-base);
      border-radius: var(--sem-radius-sm);
    }
  }
}
</style>
