<script>
import LikeButtonPipe from "../LikeButton/LikeButtonPipe.vue";
import AvatarChipPipe from "../Avatar/AvatarChipPipe.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import TextArea from "../TextArea/TextArea.vue";
import Button from "../Button/Button.vue";
import EditorBox from "../EditorBox/EditorBox.vue";
import Chip from "../Chip/Chip.vue";
export default {
  name: "PostView",
  components: {
    LikeButtonPipe,
    AvatarChipPipe,
    Paragraph,
    Dropdown,
    TextArea,
    Button,
    EditorBox,
    Chip
  },
  props: {
    id: {
      type: String,
      required: true
    },
    text: {
      type: [Object, String],
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    posted: {
      type: String,
      required: true
    },
    dropdownItems: {
      type: Array,
      default: () => []
    },
    showDiscussionLikes: {
      type: Boolean,
      default: false
    },
    replyCount: {
      type: Number,
      default: 0
    },
    parentPostId: {
      type: String,
      default: null
    },
    isAtMaxDepth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editableText: this.text,
      editMode: false,
      chipSize: "medium",
      userInput: { type: "doc", content: [{ type: "paragraph" }] },
      clickedReplyChip: false
    };
  },
  computed: {
    formattedReplyLabel() {
      return this.replyCount === 0 ? "No Replies" : `${this.replyCount} Repl${this.replyCount > 1 ? "ies" : "y"}`;
    },
    showReplyBox() {
      return this.clickedReplyChip || !this.parentPostId;
    }
  },
  watch: {
    text(newVal) {
      this.editableText = newVal;
    }
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    handleEditorBoxPrimaryButtonClick() {
      const payload = {
        text: this.editableText,
        id: this.id,
      };
      this.$emit("edit", payload);
      this.toggleEditMode();
    },
    handleChipClick() {
      this.clickedReplyChip = !this.clickedReplyChip;
    },
    handleSubmit() {
      if (this.$refs.textArea.isEmpty()) {
        return;
      } else {
        const payload = {
          text: this.userInput,
          parentPostId: this.id,
        };
        this.$emit("reply", payload);
        // Just reset input, keep reply box open
        this.userInput = { type: "doc", content: [{ type: "paragraph" }] };
        //keep clickedReplyChip true so that the user can see the new reply appear
      }
    },
  },
};
</script>

<template>
  <div v-if="!isAtMaxDepth" class="suaw-post">
    <EditorBox
      v-if="editMode"
      v-model="editableText"
      size="medium"
      button-text="Confirm"
      secondary-button-text="Cancel"
      @submit="handleEditorBoxPrimaryButtonClick"
      @secondary-click="toggleEditMode"
    />
    <Paragraph
      v-else
      size="large"
      weight="bold"
      :text="text"
    />

    <footer class="suaw-post__footer">
      <div class="suaw-post__block">
        <div class="suaw-post__status-block">
          <AvatarChipPipe
            :user-id="userId"
            :size="chipSize"
          />
          <span class="suaw-post__posted--small">{{ posted }}</span>
        </div>
        <div class="suaw-post__status-block">
          <Chip
            v-if="parentPostId"
            :size="chipSize"
            chipType="icon"
            status="info"
            icon="IconComment"
            shape="square"
            :label="formattedReplyLabel"
            class="suaw-chip--clickable"
            @click="handleChipClick"
          />
          <LikeButtonPipe
            v-if="showDiscussionLikes"
            :post-id="id"
            :size="chipSize"
          />
          <Dropdown
            iconDropdown="IconMoreHorizontal"
            dropdownButtonSize="small"
            buttonType="secondary-outline"
            buttonClassName="suaw-dropdown__button--chip"
            :items="dropdownItems"
            @editModeToggled="toggleEditMode"
            @item-click="$emit('dropdown-item-click', $event)"
          />
        </div>
      </div>
    </footer>

    <div v-if="showReplyBox" class="suaw-post__block--hidden">
      <div class="suaw-post__block--textarea-holder">
        <TextArea
          ref="textArea"
          v-model="userInput"
          placeholder="Type your reply here..."
          borderStyle="ghost"
          :use-tip-tap="true"
        />
        <Button
          size="small"
          type="primary-light"
          buttonText="Reply"
          @click="handleSubmit"
        />
      </div>
      <slot></slot>
    </div>
  </div>

  <div v-else class="suaw-reply__list-item">
    <EditorBox
      v-if="editMode"
      v-model="editableText"
      size="medium"
      button-text="Confirm"
      secondary-button-text="Cancel"
      @submit="handleEditorBoxPrimaryButtonClick"
      @secondary-click="toggleEditMode"
    />
    <Paragraph
      v-else
      size="small"
      weight="bold"
      :text="text"
    />

    <footer class="suaw-reply__footer--reply">
      <div class="suaw-reply__block--reply">
        <AvatarChipPipe
          :user-id="userId"
          :size="chipSize"
        />
        <small class="suaw-reply__posted--small">{{ posted }}</small>
      </div>
      <div class="suaw-reply__block">
        <LikeButtonPipe
          v-if="showDiscussionLikes"
          :post-id="id"
          :size="chipSize"
        />
        <Dropdown
          iconDropdown="IconMoreHorizontal"
          dropdownButtonSize="small"
          buttonType="secondary-outline"
          buttonClassName="suaw-dropdown__button--chip"
          :items="dropdownItems"
          @editModeToggled="toggleEditMode"
          @item-click="$emit('dropdown-item-click', $event)"
        />
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-post {
  &__footer {
    margin-top: var(--sem-space-triple);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__posted--small {
    color: var(--sem-color-text-regular);
    font-size: 12px;
  }

  &__block--hidden {
    flex-direction: column;
    position: relative;
  }

  &__block--textarea-holder {
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    flex-direction: column;
    gap: var(--sem-space-base);
    display: flex;
    align-items: flex-end;
    padding: var(--sem-space-base);
    margin: var(--sem-space-double) 0;
  }
}

.suaw-reply {
  &__list-item {
    background-color: var(--sem-color-background-light);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    border-radius: var(--sem-radius-sm);
  }

  &__footer--reply {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block--reply {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__posted--small {
    font-size: 12px;
    color: var(--sem-color-text-regular);
  }
}
</style>
