<script>
import { GetUserAvatar } from "./avatarOperations.gql";
import AvatarView from "./AvatarView.vue";

export default {
  name: "AvatarPipe",
  components: {
    AvatarView
  },
  inject: {
    injectedUserId: {
      from: "userId",
      default: null
    }
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loadingCount: 0,
      resultUsersView: {
        users_view: []
      }
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    user() {
      return this.resultUsersView.length > 0 ? this.resultUsersView[0] : null;
    },
    effectiveUserId() {
      return this.userId || this.injectedUserId();
    }
  },
  mounted() {
    this.$root.$on("universal-avatar-updated", this.onAvatarUpdated);
  },
  beforeDestroy() {
    this.$root.$off("universal-avatar-updated", this.onAvatarUpdated);
  },
  methods: {
    onAvatarUpdated(updatedUserId) {
      if (updatedUserId === this.effectiveUserId) {
        this.$apollo.queries.resultUsersView.refetch();
      }
    }
  },
  apollo: {
    resultUsersView: {
      query: GetUserAvatar,
      variables() {
        return {
          userId: this.effectiveUserId
        };
      },
      loadingKey: "loadingCount",
      skip() {
        return !this.effectiveUserId;
      }
    }
  }
};
</script>

<template>
  <AvatarView v-if="isLoading" :is-loading="true" :size="size" :class-name="className"></AvatarView>
  <AvatarView
    v-else
    :is-loading="false"
    :display-name="user.display_name"
    :initials="user.initials"
    :url="user.avatar_url"
    :color="user.avatar_color"
    :is-avatar-hidden="user.is_avatar_hidden"
    :is-deactivated="!!user.deleted_at"
    :size="size"
    :class-name="className"
  />
</template>
