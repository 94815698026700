var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suaw-discussion"},[(!_vm.isAtMaxDepth)?_c('Heading',{attrs:{"level":5,"content":_vm.formattedResponseLabel}}):_vm._e(),_c('ul',{class:{
    'suaw-discussion-list': !_vm.isAtMaxDepth,
    'suaw-reply__list': _vm.isAtMaxDepth
  }},[_vm._l((_vm.replyPosts),function(post,index){return _c('li',{key:index,class:{
        'suaw-discussion-list__item': !_vm.isAtMaxDepth,
        'suaw-reply__list-item': _vm.isAtMaxDepth
      }},[_c('PostPipe',{attrs:{"post-id":post.id,"parent-post-id":post.parentPostId}})],1)}),_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }