import {
  LoginByToken,
  LoginByPassword,
  ResetPassword,
  UploadUserAvatar,
  UpdateUserProfile,
  RequestPasswordReset,
  RegisterUser,
  CompleteRegistration,
  ChangePassword,
  VerifyPassword,
  UpdateUserAuthEmail,
  ReportComplaintProfile,
  ReportComplaintAvatar,
  ReportComplaintPost,
  SendEmailVerification
} from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("UserApi", { level: DEBUG });

export async function loginByToken(token) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "The code you entered does not exist."],
    [BE.EXPIRED, "This code is expired. Please request a new one."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETED, "This user's account has been deactivated."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.query({
        query: LoginByToken,
        variables: { token }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function loginByPassword(email, password) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PASSWORD, "Your email or password was incorrect. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETED, "This user's account has been deactivated."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.query({
        query: LoginByPassword,
        variables: {
          email,
          password
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function resetPassword(userId, password) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.INVALID_FORMAT, "Password format is invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Unable to update password. Please try again or contact support if the issue persists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ResetPassword,
        variables: {
          userId,
          password
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uploadUserAvatar(userId, avatarBase64Image) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PERMISSION, "Permission Denied."],
    [BE.NOT_EXIST, "User does not exist."],
    [BE.INVALID_FORMAT, "Image is either improperly encoded or not a valid gif, jpg, or png."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Image upload failed. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UploadUserAvatar,
        variables: {
          userId,
          avatarBase64Image
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateUserProfile(
  userId,
  firstName,
  lastName,
  isLastNameHidden,
  countryAbbrev,
  postalCode,
  unitSystem,
  descriptionJson,
  avatarColor,
  languages,
  city,
  region
) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This user does not exist."],
    [BE.INVALID_FORMAT, "This email format is invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Update failed. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateUserProfile,
        variables: {
          userId,
          firstName,
          lastName,
          isLastNameHidden,
          countryAbbrev,
          postalCode,
          unitSystem,
          descriptionJson,
          avatarColor,
          languages,
          city,
          region
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function requestPasswordReset(email) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.INVALID_FORMAT, "Email format is invalid."],
    [BE.NOT_EXIST, "An account with this email does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Unable to create password reset token. Please try again or contact support if the issue persists."],
    [BE.EXTERNAL, "Unable to send password reset token. Please try again or contact support if the issue persists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: RequestPasswordReset,
        variables: {
          email
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function registerUser(firstName, lastName, email, password) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.INVALID_FORMAT, "Email format is invalid."],
    [BE.ALREADY_EXISTS, "An account with this email already exists. Log in or reset your password."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Unable to save registration. Please try again or contact support if the issue persists."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: RegisterUser,
        variables: {
          firstName,
          lastName,
          email,
          password
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function sendEmailVerification(userId, isUpdated) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Unable to send new code. Please try again or contact support if the issue persists."],
    [BE.HISTORY_SUCCESS_FAILED, "The code failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The code failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: SendEmailVerification,
        variables: {
          userId,
          isUpdated
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function completeRegistration(userId, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, city, region) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This user does not exist."],
    [BE.INVALID_FORMAT, "This email format is invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Update failed. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CompleteRegistration,
        variables: {
          userId,
          firstName,
          lastName,
          isLastNameHidden,
          countryAbbrev,
          postalCode,
          city,
          region
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function changePassword(userId, passwordOld, passwordNew) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.PERMISSION, "You don't have permission to change this password."],
    [BE.PASSWORD, "Your previous password was incorrect. Please try again."],
    [BE.INVALID_FORMAT, "Password format is invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Your password failed to update. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ChangePassword,
        variables: {
          userId,
          passwordOld,
          passwordNew
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function verifyPassword(userId, password) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([[BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]]);
  const response = await call(
    () =>
      hasuraClient.query({
        query: VerifyPassword,
        variables: {
          userId,
          password
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateUserAuthEmail(userId, emailPending) {
  const validationReasonMap = new Map([
    [BE.PERMISSION, "You do not have permission to change this email."],
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "There was an error updating your email."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateUserAuthEmail,
        variables: {
          userId,
          emailPending
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reportComplaintProfile(userId, comment) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Reported user does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error saving the reported complaint."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReportComplaintProfile,
        variables: {
          userId,
          comment
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reportComplaintAvatar(userId, comment) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Reported user does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error saving the reported complaint."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReportComplaintAvatar,
        variables: {
          userId,
          comment
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function reportComplaintPost(postId, comment) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Reported post does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error saving the reported complaint."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ReportComplaintPost,
        variables: {
          postId,
          comment
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}
