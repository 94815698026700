import adminGuard from "@/utils/auth/adminGuard";

export default [
  {
    path: "/a/challenges",
    name: "ChallengeList",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/challengeList/ChallengeList.vue")
  },
  {
    path: "/a/challenges/manage",
    name: "ChallengeManage",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/challengeManage/ChallengeManage.vue")
  },
  {
    path: "/challenges",
    name: "ChallengeDiscovery",
    beforeEnter: adminGuard,
    component: () => import("@/screens/challengeDiscovery/ChallengeDiscovery.vue")
  },
  {
    path: "/challenge/:id/:slug",
    name: "Challenge",
    beforeEnter: adminGuard,
    component: () => import("@/screens/challenge/Challenge.vue")
  },
  {
    path: "/challenge/:challengeId/:challengeSlug/prompt/:promptId/:promptSlug",
    name: "ChallengePromptSubmission",
    beforeEnter: adminGuard,
    component: () => import("@/screens/challengePromptSubmission/ChallengePromptSubmission.vue")
  }
];
