<script>
import Icon from "../Icon/Icon.vue";
import Pill from "../Pill/Pill.vue";

export default {
  name: "ChipTwo",
  components: { Icon, Pill },
  props: {
    type: {
      type: String,
      default: "text",
      validator: function (value) {
        return ["text", "icon", "icon-right"].indexOf(value) !== -1;
      },
    },
    size: {
      type: String,
      default: "medium",
      validator: function (value) {
        return ["medium", "small"].indexOf(value) !== -1;
      },
    },
    color: {
      type: String,
      default: "default",
      validator(value) {
        return ["default", "online", "info", "success", "critical", "none"].indexOf(value) !== -1;
      },
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "carbon",
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
    },
    text: {
      type: String,
      default: "",
    },
    pillText: {
      type: String,
      default: "",
    },
  },
  computed: {
    iconChip() {
      return this.type === "icon";
    },
    iconChipRight() {
      return this.type === "icon-right";
    },
    textChip() {
      return this.type === "text";
    },
    computedText() {
      return this.isDeactivated ? "Community Member" : this.text;
    }
  },
};
</script>

<template>
  <div :class="['suaw-chip', 'suaw-chip--' + size, 'suaw-chip--' + color]">
    <div v-if="iconChip" class="suaw-chip__content suaw-chip__icon-chip">
      <Icon
        class="suaw-chip__icon"
        :icon="icon"
        :color="iconColor"
        :size="size === 'small' ? 'small' : 'medium'"
      />
      <p class="suaw-chip__text">{{ text }}</p>
      <Pill
        v-if="pillText"
        class="suaw-chip__pill"
        :text="pillText"
        status="lightest"
      />
    </div>
    <div
      v-if="iconChipRight"
      class="suaw-chip__content suaw-chip__icon-right-chip"
    >
      <p class="suaw-chip__text">{{ text }}</p>
      <Icon
        class="suaw-chip__icon"
        :icon="icon"
        :color="iconColor"
        :size="size === 'small' ? 'small' : 'medium'"
      />
      <Pill v-if="pillText" :text="pillText" status="lightest" />
    </div>
    <div v-if="textChip" class="suaw-chip__content suaw-chip__text-chip">
      <p class="suaw-chip__text">{{ text }}</p>
      <Pill
        v-if="pillText"
        class="suaw-chip__pill"
        :text="pillText"
        status="lightest"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-chip {
  display: inline-flex;
  align-items: center;
  width: auto;
  height: 26px;
  border: 0.5px solid #dcdee9;
  border-radius: var(--sem-radius-lg, 16px);
  background: var(--sem-color-neutral-light);

  &__text {
    white-space: nowrap;
    margin-top: 1px;
    color: var(--sem-color-neutral-dark);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;

    .suaw-chip--small & {
      font-size: 12px;
    }

    // Ensure text color matches the state
    .suaw-chip--online & {
      color: var(--sem-color-complementary-medium);  // Purple text color for online events
    }

    .suaw-chip--info & {
      color: var(--sem-color-info-medium);
    }

    .suaw-chip--success & {
      color: var(--sem-color-success-medium);
    }

    .suaw-chip--critical & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-chip--default & {
      color: var(--sem-color-text-medium);
    }
  }

  &__content {
    display: inline-flex;
    align-items: center;
  }

  &__icon-chip {
    margin-right: 8px;
    margin-left: 4px;

    p {
      margin-left: 3px;
    }
  }

  &__icon-right-chip {
    gap: 4px;
    margin-left: 8px;
    margin-right: 6px;
  }

  &__text-chip {
    margin: 0px 8px;
  }

  &__pill {
    margin-left: 4px;
  }

  // Styles for small size
  &--small {
    height: 19px;
  }

  // Background color styles
  &--default {
    background-color: var(--sem-color-neutral-light);
  }

  &--online {
    background-color: var(--sem-color-complementary-light);  // Light purple background
  }

  &--info {
    background-color: var(--sem-color-info-light);
  }

  &--success {
    background-color: var(--sem-color-success-light);
  }

  &--critical {
    background-color: var(--sem-color-critical-light);
  }

  &--none {
    background-color: none;
  }
}
</style>
