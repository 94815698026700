<script>
import PostReplyListView from "./PostReplyListView.vue";
import { GetPostReplies } from "./postOperations.gql";

export default {
  name: "PostReplyListPipe",
  components: {
    PostReplyListView
  },
  props: {
    parentPostId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      resultGetPostReplies: null,
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    replyPosts() {
      return (this.resultGetPostReplies || []).map(reply => ({
        id: reply.id,
        parentPostId: this.parentPostId
      }));
    },
    isAtMaxDepth() {
      const threadDepth = this.resultGetPostReplies?.[0]?.thread.thread_depth;
      const maxDepth = this.resultGetPostReplies?.[0]?.thread.channel.max_thread_depth;
      return threadDepth === maxDepth;
    }
  },
  apollo: {
    resultGetPostReplies: {
      query: GetPostReplies,
      variables() {
        return {
          parentPostId: this.parentPostId
        };
      },
      loadingKey: "loadingCount"
    }
  },
  created() {
    this.$root.$on("postpipe_append", this.handleAppend);
  },
  beforeDestroy() {
    this.$root.$off("postpipe_append", this.handleAppend);
  },
  methods: {
    handleAppend(reply) {
      if (reply.parentPostId === this.parentPostId) {
        // Add to local data
        const thread = reply.thread;
        thread.thread_depth = thread.thread_depth + 1;
        this.resultGetPostReplies = [...(this.resultGetPostReplies || []), { id: reply.id, thread: thread }];
      }
    }
  }
};
</script>

<template>
  <PostReplyListView v-if="!isLoading" :reply-posts="replyPosts" :is-at-max-depth="isAtMaxDepth" />
  <div v-else>
    Loading...
  </div>
</template>
