<script>
import PostView from "./PostView.vue";
import PostReplyListPipe from "./PostReplyListPipe.vue";
import { formatDistanceToNow } from "date-fns";
import * as DiscussionApi from "@/features/discussions/api.js";
import { GetPost } from "./postOperations.gql";

export default {
  name: "PostPipe",
  components: {
    PostView,
    PostReplyListPipe
  },
  props: {
    postId: {
      type: String,
      required: true
    },
    parentPostId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      resultGetPost: null,
      post: null,
      removedPostBodyJson: {
        type: "doc",
        content: [{ type: "paragraph", content: [{ type: "text", text: "This post has been hidden.", marks: [{ type: "italic" }] }] }]
      },
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    isAuthenticated() {
      return this.$auth && this.$auth.isAuthenticated;
    },
    userId() {
      return this.isAuthenticated ? this.$auth.user.id : null;
    },
    isUserAdmin() {
      return this.isAuthenticated && this.$auth.user.role === "app_admin";
    },
    dropdownItems() {
      if (!this.post) return [];
      return this.getDropdownItems(this.post.author.id, this.post.id, this.post.isRemoved);
    },
    isAtMaxDepth() {
      const threadDepth = this.resultGetPost?.thread.thread_depth;
      const maxDepth = this.resultGetPost?.thread.channel.max_thread_depth;
      return threadDepth === maxDepth;
    }
  },
  apollo: {
    resultGetPost: {
      query: GetPost,
      variables() {
        return {
          id: this.postId,
          userId: this.userId
        };
      },
      result({ data }) {
        this.resultGetPost = data.resultGetPost;
        if (this.resultGetPost) {
          const isRemoved = this.resultGetPost.deleted_at !== null || this.resultGetPost.author.deleted_at !== null;
          const isEdited = !!this.resultGetPost.last_edited_at;

          // Sum up all posts in all reply threads
          const replyCount = this.resultGetPost.posts_threads.reduce((total, thread) => {
            return total + (thread.threads_posts_aggregate?.aggregate?.count || 0);
          }, 0);

          this.post = {
            id: this.resultGetPost.id,
            text: isRemoved ? this.removedPostBodyJson : this.resultGetPost.body_json,
            author: {
              id: this.resultGetPost.author.id,
              isDeactivated: this.resultGetPost.author.deleted_at !== null,
              displayName: this.resultGetPost.author.display_name
            },
            channelId: this.resultGetPost.thread.channel_id,
            isRemoved,
            posted: isEdited
              ? `${this.getRelativeTime(this.resultGetPost.posted_at)} (edited ${this.getRelativeTime(this.resultGetPost.last_edited_at)})`
              : this.getRelativeTime(this.resultGetPost.posted_at),
            replyCount
          };
        }
      },
      loadingKey: "loadingCount"
    }
  },
  methods: {
    getRelativeTime(posted_at) {
      return formatDistanceToNow(new Date(posted_at), { addSuffix: true });
    },
    getDropdownItems(authorId, postId, isPostRemoved) {
      if (!this.isAuthenticated || (!this.isUserAdmin && isPostRemoved)) return [];

      const options = [];
      const isOwnPost = authorId === this.userId;

      if (this.isUserAdmin) {
        if (isPostRemoved) {
          options.push({ icon: "IconShow", text: "Unremove", postId, authorId });
        } else {
          if (isOwnPost) {
            options.push({ icon: "IconEdit", text: "Edit", postId, authorId }, { icon: "IconHide", text: "Remove", postId, authorId });
          } else {
            options.push({ icon: "IconUserVoice", text: "Report", postId, authorId }, { icon: "IconHide", text: "Remove", postId, authorId });
          }
        }
      } else {
        if (isOwnPost) {
          options.push({ icon: "IconEdit", text: "Edit", postId, authorId }, { icon: "IconHide", text: "Remove", postId, authorId });
        } else {
          options.push({ icon: "IconUserVoice", text: "Report", postId, authorId });
        }
      }

      return options;
    },
    async handleEdit(payload) {
      const editResult = await DiscussionApi.editPost(payload.id, payload.text);
      if (!editResult.success) {
        this.$root.$emit("universal-error-message", editResult.error);
        return;
      }
      this.post.text = payload.text;
    },
    async handleReply(payload) {
      const draftResult = await DiscussionApi.draftPost(this.post.channelId, payload.text, payload.parentPostId);
      if (!draftResult.success) {
        this.$root.$emit("universal-error-message", draftResult.error);
        return;
      }

      const commitResult = await DiscussionApi.commitPost(draftResult.result.id);
      if (!commitResult.success) {
        this.$root.$emit("universal-error-message", commitResult.error);
        return;
      }

      // Update local data
      this.post.replyCount++;
      this.$root.$emit("postpipe_append", {
        id: draftResult.result.id,
        parentPostId: payload.parentPostId,
        thread: {
          thread_depth: this.resultGetPost.thread.thread_depth,
          channel: {
            max_thread_depth: this.resultGetPost.thread.channel.max_thread_depth
          }
        }
      });
    },
    async handleDropdownItemClick(option) {
      if (option.text === "Remove") {
        const result = await DiscussionApi.removePost(option.postId);
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        }
      } else if (option.text === "Unremove") {
        const result = await DiscussionApi.unremovePost(option.postId);
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        }
      } else if (option.text === "Report") {
        this.$root.$emit("universal-complaint", {
          context: "Post",
          postId: option.postId
        });
      }
      // Update local data
      if (option.text === "Remove") {
        this.post.isRemoved = true;
        this.post.text = this.removedPostBodyJson;
      } else if (option.text === "Unremove") {
        this.post.isRemoved = false;
        this.post.text = this.resultGetPost.body_json;
      }
    },
  }
};
</script>

<template>
  <div v-if="!isLoading">
    <PostView
      v-if="post"
      :id="post.id"
      :text="post.text"
      :user-id="post.author.id"
      :posted="post.posted"
      :show-discussion-likes="true"
      :dropdown-items="dropdownItems"
      :parent-post-id="parentPostId"
      :reply-count="post.replyCount"
      :is-at-max-depth="isAtMaxDepth"
      @edit="handleEdit"
      @reply="handleReply"
      @dropdown-item-click="handleDropdownItemClick"
    >
      <PostReplyListPipe v-if="parentPostId" :parent-post-id="postId" />
    </PostView>
    <div v-else>
      Post Not Found
    </div>
    <PostReplyListPipe v-if="!parentPostId" :parent-post-id="postId" />
  </div>
  <div v-else>
    Loading...
  </div>
</template>
