import { getInstance } from "./index";
// import { Logger, INFO } from "../plugins/logging";
import router from "../../router";

// const $log = new Logger("GUARD>AUTH", { level: INFO });

export default (to, _, next) => {
  // $log.info("protected route: checking that user is authenticated");
  const authService = getInstance();

  const fn = () => {
    // $log.info("user authenticated", authService.isAuthenticated);
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      return next();
    }

    if (to.name === "LogIn") {
      return next();
    }
    if (to.name === "SignUp") {
      return next();
    }
    if (to.name === "LogOut") {
      return next();
    }
    if (to.name === "ResetPasswordToken") {
      return next();
    }
    if (to.name === "CompleteAccountToken") {
      return next();
    }
    if (to.name === "DashboardSettingsViewToken") { //hate this but we allow login-by-token to it
      return next();
    }

    // Otherwise, sign in
    router.push({
      name: "LogIn",
      query: {
        redirectUrl: to.fullPath
      }
    });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) return fn();

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) return fn();
  });
};
