<script>
import Icon from "../Icon/Icon.vue";

export default {
  name: "Alert",
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      default: "neutral",
      validator: function (value) {
        return [
          "neutral",
          "critical",
          "success",
          "info",
        ].indexOf(value) !== -1;
      },
    },
    message: {
      type: String,
      default: "This is a neutral message",
    },
  },
  data() {
    return {
      iconMap: {
        success: "IconCalendarCheck",
        neutral: "IconCalendarCheck",
        critical: "IconCalendarX",
        info: "IconCalendar"
      }
    };
  },
  computed: {
    alertIcon() {
      return this.iconMap[this.type];
    },
  }
};
</script>

<template>
  <div 
    class="suaw-alert"
    :class="'suaw-alert--' + type"
  >
    <Icon
      class="suaw-alert__icon"
      :icon="alertIcon"
      :key="alertIcon.toString()"
    />
    <strong class="suaw-alert__text">
      {{ message }}
    </strong>
  </div>
</template>

<style lang="scss" scoped>
.suaw-alert {
  border-radius: var(--sem-radius-md);
  box-shadow: var(--soft-shadow-draft);
  display: inline-flex;
  align-items: center;
  gap: var(--sem-space-double);
  padding: var(--sem-space-triple);
  overflow: hidden;
  position: relative;
  width: 100%;

  &--success {
    background-color: var(--sem-color-success-light);
  }

  &--info {
    background-color: var(--sem-color-info-light);
  }

  &--critical {
    background-color: var(--sem-color-critical-light);
  }

  &--neutral {
    background-color: var(--sem-color-neutral-light);
  }

  &__text {
    font-family: var(--h-5-font-family);
    font-style: var(--h-5-font-style);
    font-weight: var(--h-5-font-weight);
    letter-spacing: var(--h-5-letter-spacing);
    line-height: var(--h-5-line-height);

    .suaw-alert--success & {
      color: var(--sem-color-success-medium);
    }

    .suaw-alert--info & {
      color: var(--sem-color-info-medium);
    }

    .suaw-alert--critical & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-alert--neutral & {
      color: var(--sem-color-text-darken);
    }
  }

  &__icon {
    height: 24px;
    width: 24px;

    .suaw-alert--success & {
      fill: var(--sem-color-success-medium);
    }

    .suaw-alert--info & {
      fill: var(--sem-color-info-medium);
    }

    .suaw-alert--critical & {
      fill: var(--sem-color-critical-medium);
    }

    .suaw-alert--neutral & {
      fill: var(--sem-color-text-darken);
    }
  }
}
</style>
