<script>
import Button from "../Button/Button.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import TextArea from "../TextArea/TextArea.vue";
import Divider from "../Divider/Divider.vue";
export default {
  name: "EditorBox",
  components: {
    Button,
    Paragraph,
    TextArea,
    Divider
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        type: "doc",
        content: [{ type: "paragraph" }]
      })
    },
    statusText: {
      type: String,
      default: ""
    },
    statusDate: {
      type: String,
      default: ""
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    inputMessage: {
      type: String,
      default: "Create a post here."
    },
    buttonText: {
      type: String,
      default: "Submit"
    },
    secondaryButtonText: {
      type: String,
      default: ""
    },
    clickMethod: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      localValue: this.value
    };
  },
  computed: {
    iconColor() {
      return this.readOnly ? "gray" : "blue";
    }
  },
  watch: {
    value(newVal) {
      this.localValue = newVal;
    },
    immediate: true,
    deep: true
  },
  methods: {
    updateValue(newVal) {
      this.$emit("input", newVal);
    },
    handleImageUpload(base64Data) {
      this.$emit("image-upload", base64Data);
    },
    handleSubmit() {
      if (this.$refs.textArea.isEmpty()) {
        return;
      } else {
        this.$emit("submit", this.localValue);
        this.localValue = { type: "doc", content: [{ type: "paragraph" }] };
      }
    }
  }
};
</script>

<template>
  <div class="suaw-editor-box">
    <div class="suaw-editor-box__content">
      <TextArea
        ref="textArea"
        v-model="localValue"
        :placeholder="inputMessage"
        border-style="ghost"
        :use-tip-tap="true"
        :use-formatting="true"
        :can-resize="true"
        :allow-images="false"
        @input="updateValue"
        @image-upload="handleImageUpload"
      />
    </div>
    <Divider />
    <footer v-if="!readOnly" class="suaw-editor-box__footer">
      <div class="suaw-editor-box__footer-section">
        <Paragraph weight="bolder" size="small" :text="statusText" />
        <Paragraph class="suaw-editor-box__release-date" weight="bold" size="small" :text="statusDate" />
      </div>
      <div class="suaw-editor-box__footer-button-section">
        <Button v-if="secondaryButtonText" type="secondary-outline" :button-text="secondaryButtonText" size="large" @click="$emit('secondary-click')" />
        <Button type="primary" :button-text="buttonText" size="large" :click-method="clickMethod" @click="handleSubmit" />
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.suaw-editor-box {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  background-color: var(--sem-color-background-lightest);
  border: 1px solid var(--sem-color-border-medium);
  border-radius: var(--sem-radius-sm);
  flex-direction: column;
  padding: var(--sem-space-double);

  &__content {
    display: flex;
    min-height: 100px;
  }

  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__footer-section {
    display: flex;
    justify-content: center;
    gap: var(--sem-space-half);
  }

  &__footer-button-section {
    display: flex;
    justify-content: center;
    gap: var(--sem-space-double);
  }

  @media (max-width: 650px) {
    &__footer {
      flex-direction: column;
      gap: var(--sem-space-double);
    }

    &__footer-section {
      width: 100%;
      justify-content: flex-start;
    }

    &__footer-button-section {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: var(--sem-space-base);

      // If there's only one button, make it full width
      :only-child {
        grid-column: 1 / -1;
      }
    }
  }

  &__release-date {
    color: var(--sem-color-neutral-medium);
  }
}
</style>
