<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import AvatarChipPipe from "../Avatar/AvatarChipPipe.vue";

export default {
  name: "DirectMessageItem",
  components: {
    Paragraph,
    AvatarChipPipe,
  },
  props: {
    id: {
      required: true,
      type: [String, Number, Object],
    },
    headingText: {
      type: [String, Object],
    },
    userId: {
      type: String,
      default: null,
    },
    posted: {
      type: String,
    },
    isSidepanelDirectMessage: {
      type: Boolean,
    },
    isOwner: {
      type: Boolean,
    },
  },
  computed: {
    directMessageClasses() {
      return {
        'suaw-direct-message-item__content--owner' : this.isOwner === true,
      }
    }
  },
  methods: {
  },
};
</script>

<template>
  <li
    class="suaw-direct-message-item__content"
    :class="directMessageClasses"
    :id="id"
  >
    <div class="suaw-direct-message-item__text">
      <Paragraph
        size="medium"
        weight="bold"
        :text="headingText"
      />
    </div>

    <footer class="suaw-direct-message-item__footer">
      <div class="suaw-direct-message-item__block">
        <div class="suaw-direct-message-item__status-block">
          <AvatarChipPipe size="medium" :userId="userId" />
          <span
            :class="{
              'suaw-direct-message-item__posted--small': isSidepanelDirectMessage,
              'suaw-direct-message-item__posted': !isSidepanelDirectMessage
            }"
          >{{ posted }}</span>
        </div>
      </div>
    </footer>
    <slot />
  </li>
</template>

<style lang="scss" scoped>
.suaw-direct-message-item {

  &__header,
  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    border: 1px solid var(--sem-color-border-light);
    border-radius: var(--sem-radius-sm);
    flex-direction: column;
    gap: var(--sem-space-double);
    padding: var(--sem-space-triple);
  }

  &__content:not(.suaw-direct-message-item__content--owner) {
    background-color: var(--sem-color-background-lightest);
  }

  &__content--owner {
    background-color: var(--sem-color-background-light);
    align-items: flex-end;
  }

  &__header {
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__footer {
    flex-direction: column;
  }

  &__footer--reply {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: var(--sem-space-double);
    justify-content: space-between;
  }

  &__block--reply {
    display: flex;
    align-items: center;
  }

  &__block--textarea-holder {
    margin: var(--sem-space-double) 0;
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    padding: var(--sem-space-base);
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__posted,
  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__block--hidden {
    flex-direction: column;
    position: relative;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-double);
    list-style-type: none;
    padding: 0;
    width: 100%;
  }

  &__list-item {
    padding: var(--sem-space-triple);
    background-color: var(--sem-color-background-light);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    border-radius: var(--sem-radius-sm);
  }
}
</style>
