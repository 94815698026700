<script>
import CalendarDate from "../CalendarDate/CalendarDate.vue";
import Chip from "../Chip/Chip.vue";
import Button from "../Button/Button.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Heading from "../Heading/Heading.vue";
import Modal from "../Modal/Modal.vue";
import SummaryModalJoinChapter from "./SummaryModalJoinChapter.vue";
import SummaryModalRSVP from "./SummaryModalRSVP.vue";

export default {
  name: "Summary",
  components: {
    CalendarDate,
    Chip,
    Button,
    Dropdown,
    Heading,
    Modal,
    SummaryModalJoinChapter,
    SummaryModalRSVP,
  },
  props: {
    id: {
      type: String,
      default: "summary",
    },
    summaryType: {
      type: String,
      default: "event",
      validator: function (value) {
        return [
          "series",
          "event",
          "chapter",
          "profile",
          "dashboard",
          "form",
          "text",
        ].indexOf(value) !== -1;
      },
    },
    heading: {
      type: String,
      default: "Event Name",
    },
    showPrimaryButton: {
      type: Boolean,
      default: true,
    },
    buttonLabel: {
      type: String,
    },
    buttonIcon: {
      type: String,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    eventDay: {
      type: Number,
    },
    eventMonth: {
      type: Number,
    },
    eventRSVPed: {
      type: Boolean,
    },
    chapterJoined: {
      type: Boolean,
    },
    eventSpotsLeft: {
      type: String,
    },
    eventTime: {
      type: String,
    },
    eventDuration: {
      type: String,
    },
    location: {
      type: String,
    },
    eventStatus: {
      type: String,
    },
    eventTimezone: {
      type: String,
    },
    totalMembers: {
      type: String,
    },
    totalEvents: {
      type: String,
    },
    totalPosts: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    dropdownIcon: {
      type: String,
      default: "IconMoreHorizontal",
    },
    dropdownLabel: {
      type: String,
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalClickMethod: {
      type: Function,
    },
    headingSize: {
      type: [String, Number],
      default: 2
    },
    buttonOrganizerLabel: {
      type: String,
      default: 'Edit Event'
    },
    buttonOrganizerIcon: {
      type: String,
      default: 'IconEdit'
    },
    isOrganizer: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      buttonPressed: false,
      showModal: false,
    };
  },
  computed: {
    chipComponents() {
      return [
        { status: 'info', icon: 'IconUser', label: this.totalMembers, condition: this.totalMembers && this.summaryType === 'chapter' },
        { status: 'default', icon: 'IconCalendarCheck', label: this.totalEvents, condition: this.totalEvents && this.summaryType === 'profile' },
        { status: 'default', icon: 'IconComment', label: this.totalPosts, condition: this.totalPosts && this.summaryType === 'profile' },
        { status: 'default', icon: 'IconClock', label: this.eventDuration, pill: this.eventTimezone, condition: this.eventDuration },
        { status: 'default', icon: 'IconUserCheck', label: this.eventSpotsLeft, condition: this.eventSpotsLeft },
        { status: 'default', icon: 'IconLocation', label: this.location, condition: this.location },
      ].filter(chip => chip.condition);
    },
    buttonTypes() {
      if (this.summaryType === "event") {
        return this.buttonPressed || this.eventRSVPed ? 'success-light' : 'success'
      } else if (this.summaryType === "chapter") {
        return this.chapterJoined ? 'success-light' : 'success'
      } else if (this.summaryType === "profile") {
        return this.buttonType ? this.buttonType : 'primary-light'
      } else if (this.summaryType === "series") {
        return 'primary-light'
      } else {
        return this.buttonType
      }
    },
    buttonLabels() {
      switch (this.summaryType) {
        case "chapter":
          return this.chapterJoined ? 'Joined' : 'Join Chapter';
        case "event":
          return this.buttonPressed || this.eventRSVPed ? 'RSVPed' : 'RSVP';
        case "series":
          return this.buttonLabel ? this.buttonLabel : 'View';
        case "profile":
          return this.buttonLabel ? this.buttonLabel : 'Report User';
        default:
          return this.buttonLabel;
      }
    },
    shouldShowChips() {
      if (this.summaryType != "dashboard" && this.summaryType != "form" && this.summaryType != "text") {
        return true;
      } else {
        return false;
      }
    },
    toggleModalClasses() {
      return {
        'is-visible': this.showModal
      }
    },
    buttonIcons() {
      if (this.summaryType === "chapter") {
        return this.chapterJoined ? "IconFlagFill" : "";
      } else if (this.summaryType === "event") {
        return this.eventRSVPed ? "IconDownloadDone" : "";
      } else {
        return this.buttonIcon ? this.buttonIcon : "";
      }
    },
    modalTitles() {
      switch (this.summaryType) {
        case "chapter":
          return this.modalTitle === '' ? "Confirm Join Chapter" : this.modalTitle;
        case "event":
          return this.modalTitle === '' ? "Confirm Your RSVP" : this.modalTitle;
        default:
          return this.modalTitle;
      }
    },
  },
  methods: {
    toggleModalVisibility() {
      this.showModal = !this.showModal;
    },
    toggleButtonState() {
      return this.buttonPressed = !this.buttonPressed;
    },
    toggleButtonConfirm() {
      this.buttonPressed = !this.buttonPressed;
      this.toggleModalVisibility();
    },
    toggleButtonCancel() {
      this.toggleModalVisibility();
    },
    buttonActions() {
      this.$emit("click", this.id);
      if (this.summaryType == "event" || this.summaryType == "chapter") {
        this.toggleModalVisibility();
      } else {
        this.toggleButtonState();
      }
    }
  }
}
</script>

<template>
  <div class="suaw-summary">
    <div class="suaw-summary__details">
      <CalendarDate
        v-if="summaryType == 'event' || summaryType == 'series'"
        size="small"
        :status="eventStatus"
        :month="eventMonth"
        :day="eventDay"
        key="summary-event"
      />
      <slot name="avatar" />
      <div class="suaw-summary__info">
        <div class="suaw-summary__heading">
          <Heading
            :level="headingSize"
            :content="heading"
          />
        </div>
        <div
          v-if="shouldShowChips"
          class="suaw-summary__subheading"
        >
          <Chip
            v-for="chip in chipComponents"
            :key="chip.icon"
            size="medium"
            :status="chip.status"
            :icon="chip.icon"
            :label="chip.label"
            chipType="icon"
            :pill="chip.pill"
          />
        </div>
      </div>
    </div>
    <nav class="suaw-summary__actions">
      <Button
        v-if="isOrganizer"
        :buttonText="buttonOrganizerLabel"
        class="button"
        size="large"
        type="primary-light"
        :iconLeft="buttonOrganizerIcon"
        className="suaw-summary__cta"
        @click="$emit('secondary-click')"
      />
      <Button
        v-if="summaryType != 'text' && showPrimaryButton"
        :buttonText="buttonLabels"
        class="button"
        size="large"
        :type="buttonTypes"
        :iconLeft="buttonIcons"
        className="suaw-summary__cta"
        @click="buttonActions"
      />
      <Dropdown
        v-if="summaryType != 'form' && summaryType != 'text' && dropdownItems && dropdownItems.length"
        buttonType="secondary-outline"
        :items="dropdownItems"
        :iconDropdown="dropdownIcon"
        :label="dropdownLabel"
        @item-click="$emit('dropdown-item-click', $event)"
      />
    </nav>
    <transition name="suaw-modal__fade">
      <Modal
        v-if="showModal"
        modalType="confirmation"
        :class="toggleModalClasses"
        :toggleButtonConfirm="toggleButtonConfirm"
        :toggleButtonCancel="toggleButtonCancel"
        :modalTitle="modalTitles"
        :clickMethod="modalClickMethod"
        @click="toggleModalVisibility"
      >
        <SummaryModalJoinChapter
          v-if="summaryType === 'chapter'"
          :chapter-joined="chapterJoined"
        />
        <SummaryModalRSVP
          v-if="summaryType === 'event'"
          :eventRSVPed="eventRSVPed"
        />
      </Modal>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.suaw-summary {
  flex-wrap: var(--comp-summary-wrap-content);
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 601px) {
    --comp-summary-cta-width: auto;
    --comp-summary-wrap-content: no-wrap;
  }

  @media screen and (max-width: 600px) {
    --comp-summary-cta-width: 100%;
    --comp-summary-wrap-content: wrap;
  }

  &,
  &__heading,
  &__subheading,
  &__info,
  &__actions,
  &__details,
  &__status {
    display: flex;
  }

  &__info {
    flex-direction: column;
  }

  &__info {
    justify-content: center;
  }

  &,
  &__details {
    gap: var(--sem-space-double);
  }

  &__details {
    flex-wrap: var(--comp-summary-wrap-content);
  }

  &__heading {
    align-items: center;
    margin-bottom: var(--sem-space-base);
  }

  &__heading,
  &__subheading,
  &__status {
    gap: var(--sem-space-base);
  }

  &__heading,
  &__subheading {
    flex-wrap: wrap;
  }

  &__actions {
    gap: var(--sem-space-double);
    padding: var(--sem-space-base) 0 var(--sem-space-base) var(--sem-space-base);
  }

  &__cta {
    width: var(--comp-summary-cta-width);
  }
}
</style>
