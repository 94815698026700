export default [
  {
    path: "/about/suaw",
    name: "About",
    component: () => import("@/screens/about/About.vue"),
    alias: ["/resources/about", "/about", "/about/about-suaw"]
  },
  {
    path: "/about/method",
    name: "Method",
    component: () => import("@/screens/about/Method.vue"),
    alias: ["/resources/method"]
  },
  {
    path: "/about/impact",
    name: "Impact",
    component: () => import("@/screens/about/Impact.vue"),
    alias: ["/resources/impact"]
  },
  {
    path: "/about/organization",
    name: "OurOrganization",
    component: () => import("@/screens/about/OurOrganization.vue"),
    alias: ["/resources/our-organization", "/about/our-organization"]
  },
  {
    path: "/resources/contact",
    name: "Contact",
    component: () => import("@/screens/about/Contact.vue"),
    alias: ["/contact"]
  }
];
