<script>
import HeroImage from "../../assets/Hero.png";
import clouds from "../../assets/clouds.png";
import Heading from "../Heading/Heading.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Button from "../Button/Button.vue";
export default {
  name: "WriteWithUsSection",
  components: { Heading, Paragraph, Button },
  data() {
    return {
      HeroImage,
      clouds,
    };
  },
};
</script>

<template>
  <div class="suaw-wwu-section">
    <div class="suaw-wwu-section__content">
      <div class="suaw-wwu-section__cta-container">
        <img class="suaw-wwu-section__cloud-image" :src="clouds" alt="clouds" />
        <Heading
          class="suaw-wwu-section__heading"
          level="2"
          content="Write With Us!"
        />
        <Paragraph
          class="suaw-wwu-section__description"
          text="Become part of something bigger at Shut Up & Write! Our community believes in the power of connection and creativity. When you join us, you'll make progress on your own writing while also supporting fellow writers."
        />
        <div class="suaw-wwu-section__button-container">
          <Button
            class="suaw-wwu-section__button"
            buttonText="Join the Community!"
            size="large"
            type="primary"
            @click="$emit('click')"
          />
        </div>
      </div>
      <div class="suaw-wwu-section__image-container">
        <img
          class="suaw-wwu-section__image"
          :src="HeroImage"
          alt="A large hand with a pencil. Two people dancing around it."
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-wwu-section {
  max-width: 1264px;
  width: 100%;
  height: auto;
  background: var(--sem-color-info-light);
  @media (max-width: 767px) {
    padding: 24px;
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 24px;
    @media (max-width: 840px) {
      column-gap: 48px;
    }
    @media (max-width: 767px) {
      grid-template-columns: auto;
    }
  }
  &__cta-container {
    position: relative;
    margin: 32px 8px 32px 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
    @media (max-width: 840px) {
      margin: 16px 0px 16px 32px;
    }
    @media (max-width: 767px) {
      gap: 24px;
      align-items: center;
      margin: 0;
    }
  }
  &__cloud-image {
    position: absolute;
    right: 35%;
    top: 0%;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__image-container {
    margin: 16px 8px 0px 0px;
    max-height: 305px;
    height: auto;
    width: 100%;
    aspect-ratio: 7/5;
    align-self: end;
    @media (max-width: 915px) {
      max-height: 275px;
    }
    @media (max-width: 840px) {
      max-height: 225px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__heading {
    color: #414141;
    font-family: var(--secondary-font-family);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
  }
  &__description {
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  &__button-container {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
  &__button {
    @media (max-width: 650px) {
      width: 100%;
    }
  }
}
</style>
