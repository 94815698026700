import { render, staticRenderFns } from "./AvatarChipView.vue?vue&type=template&id=3088536b&scoped=true"
import script from "./AvatarChipView.vue?vue&type=script&lang=js"
export * from "./AvatarChipView.vue?vue&type=script&lang=js"
import style0 from "./AvatarChipView.vue?vue&type=style&index=0&id=3088536b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3088536b",
  null
  
)

export default component.exports