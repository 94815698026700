<script>
import { mixin as clickaway } from "vue-clickaway";
import Button from "../Button/Button.vue";
import AvatarPipe from "../Avatar/AvatarPipe.vue";
import Icon from "../Icon/Icon.vue";
import SearchInput from "../SearchInput/SearchInput.vue";
import Pill from "../Pill/Pill.vue";

export default {
  name: "Dropdown",
  components: {
    Button,
    AvatarPipe,
    Icon,
    SearchInput,
    Pill,
  },
  mixins: [clickaway],
  props: {
    items: {
      type: Array,
    },
    dropdownType: {
      type: String,
      default: 'immutable',
      validator: function (value) {
        return ["immutable", "mutable", "combined"].indexOf(value) !== -1;
      },
    },
    className: {
      type: String,
      default: "",
    },
    buttonClassName: {
      type: String,
    },
    label: {
      type: String,
    },
    buttonType: {
      type: String,
      default: "secondary-ghost"
    },
    dropdownButtonSize: {
      type: String,
      default: "large"
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    userFullName: {
      type: String,
      default: "John Doe",
    },
    userHandle: {
      type: String,
      default: "",
    },
    iconDropdown: {
      type: String,
      default: "IconChevronDown",
    },
    iconDropdownLeft: {
      type: String,
    },
    totalNotifications: {
      type: String,
    },
    hasSearch: {
      type: Boolean,
    },
    direction: {
      type: String,
      default: 'right',
      validator: function (value) {
        return ["left", "right"].indexOf(value) !== -1;
      },
    }
  },
  data() {
    return {
      showList: false,
      selectedText: '',
      filteredItems: this.items,
    };
  },
  watch: {
    items(newItems) {
      this.filteredItems = newItems;
    },
  },
  computed: {
    toggleLabel() {
      if (this.dropdownType === 'mutable') {
        return this.selectedText || (this.items.length > 0 ? this.items[0].text : '');
      } else if (this.dropdownType === 'combined') {
        return this.label + ' ' + (this.selectedText || (this.items.length > 0 ? this.items[0].text : ''));
      } else {
        return this.label || '';
      }
    },
    toggleClassName() {
      return {
        'is-visible': this.showList
      }
    },
    isMutableDropdown() {
      return this.dropdownType === 'mutable';
    },
    isCombinedDropdown() {
      return this.dropdownType === 'combined';
    },
    directionClasses() {
      return {
        'suaw-dropdown__box--left': this.direction == 'left',
        'suaw-dropdown__box--right': this.direction == 'right',
        'suaw-dropdown__box--full-width': this.className.includes('dropdown--full-width'),
      }
    },
  },
  methods: {
    away() {
      this.showList = false;
    },
    toggleListVisibility() {
      if (!this.isSearchResults) {
        this.showList = !this.showList;
      }
    },
    onItemClick(item) {
      this.$emit("item-click", item);
      this.selectedText = item.text;

      if (item.text === "Edit") {
        this.$emit("editModeToggled");
      }

      if (item.reply === true) {
        this.$emit("editReplyModeToggled");
      }

      if (item.text === "Report") {
        this.$emit("reportModalToggled");
      }

      if (item.function && typeof item.function === 'function') {
        item.function(item);
      }

      if (this.hasSearch) {
        this.$refs.searchInput.searchTerm = '';
        this.$emit('clear');
        this.filteredItems = this.items;
      }
      this.showList = false;
    },
    handleSearch(searchTerm) {
      if (searchTerm.trim() === '') {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = this.items.filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()));
      }
    },
  },
  directives: {
    clickaway: clickaway.directive,
  },
};
</script>

<template>
  <div
    class="suaw-dropdown"
    :class="[toggleClassName, className]"
    v-on-clickaway="away"
  >
    <Button
      :size="dropdownButtonSize"
      :iconRight="iconDropdown"
      :iconLeft="iconDropdownLeft"
      :buttonText="toggleLabel"
      :type="buttonType"
      @click="toggleListVisibility"
      :class="['suaw-dropdown__button', buttonClassName]"
      :showAvatar="showAvatar"
    >
      <Pill
        v-if="totalNotifications"
        :text="totalNotifications"
        status="critical"
        class="suaw-dropdown__pill"
      />
    </Button>
    <div
      class="suaw-dropdown__box"
      :class="directionClasses"
    >
      <header
        v-if="showAvatar"
        class="suaw-dropdown__header"
      >
        <AvatarPipe
          size="medium"
        />
        <p class="suaw-dropdown__details">
          <strong class="suaw-dropdown__headline">{{ label }}</strong>
          <span
            v-if="userHandle"
            class="suaw-dropdown__subheading"
          >
            @{{ userHandle }}
          </span>
        </p>
      </header>
      <ul class="suaw-dropdown__list">
        <li
          v-if="hasSearch"
          class="suaw-dropdown__item suaw-dropdown__item--search"
        >
          <SearchInput
            @search="handleSearch"
            ref="searchInput"
            showIcon
          />
        </li>
        <li
          v-for="item in filteredItems || items"
          :key="item.text || item.url"
          class="suaw-dropdown__item"
        >
          <template v-if="item.url">
            <a
              class="suaw-dropdown__link"
              :href="item.url"
            >
              <AvatarPipe
                v-if="item.showAvatar"
                size="small"
                :userId="item.userId"
              />
              <Icon
                v-if="item.icon"
                :icon="item.icon"
                className="suaw-dropdown__icon"
              />
              <span class="suaw-dropdown__label">{{ item.text }}</span>
              <Pill
                v-if="item.notificationCounter"
                :text="item.notificationCounter"
                status="critical"
              />
            </a>
          </template>
          <template v-else>
            <Button
              className="suaw-dropdown__link"
              @click="onItemClick(item)"
              :showAvatar="item.showAvatar"
              :avatarBefore="true"
              :buttonText="item.text"
              :iconLeft="item.icon"
              :pillText="item.notificationCounter"
              :pillStatus="item.notificationCounter ? 'critical' : ''"
              classNameText="font-weight-regular"
              type="secondary-ghost"
            />
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-dropdown {
  display: inline-block;
  position: relative;

  &__button--no-pd {
    padding-left: var(--sem-space-none);
    padding-right: var(--sem-space-none);
  }

  &__button--fill {
    justify-content: space-between;
  }

  &__button--fit {
    justify-content: flex-start;
  }

  &__button--fill,
  &__button--fit {
    width: 100%;
  }

  &__button--chip {
    height: auto;
  }

  &__box {
    background: var(--sem-color-background-lightest);
    border-radius: var(--sem-radius-md);
    box-shadow: var(--soft-shadow-draft);
    padding: var(--sem-space-double);
    position: absolute;
    max-height: none;
    opacity: 0;
    overflow: hidden;
    transition-property: opacity, visibility;
    transition-duration: .25s;
    transition-timing-function: linear;
    visibility: hidden;
    width: auto;
    z-index: 97;

    .suaw-dropdown.is-visible & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__box--full-width {
    width: 100%;
  }

  &__box--left {
    left: 0;
  }

  &__box--right {
    right: 0;
  }

  &__pill {
    bottom: 8px;
    right: 20px;
    position: absolute;
  }

  &__header {
    display: flex;
    border-bottom: 1px solid var(--sem-color-border-medium);
    padding-bottom: var(--sem-space-double);
    padding-left: var(--sem-space-base);
    padding-right: var(--sem-space-base);
  }

  &__list {
    padding-left: var(--sem-space-none);
    padding-right: var(--sem-space-none);
    list-style: none;
  }

  &__list:not(:first-child) {
    padding-top: var(--sem-space-double);
  }

  &__list:not(:last-child) {
    border-bottom: 1px solid var(--sem-color-border-medium);
    padding-bottom: var(--sem-space-double);
  }

  &__item--search {
    margin-bottom: var(--sem-space-base);
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: var(--sem-space-base);
  }

  &__headline {
    white-space: nowrap;
    color: var(--sem-color-text-medium);
    font-size: var(--button-bold-font-size);
    line-height: var(--base-line-height);
  }

  &__subheading {
    color: var(--sem-color-text-medium);
    font-size: var(--body-small-font-size);
  }

  &__link {
    border-radius: var(--sem-radius-md);
    color: var(--sem-color-text-regular);
    display: flex;
    align-items: center;
    font-size: var(--body-copy-font-size);
    padding: var(--sem-space-base);
    text-decoration: none;
    transition-property: background-color, color;
    transition-duration: .25s;
    transition-timing-function: linear;
    justify-content: flex-start;
    width: 100%;
    gap: 6px;
    height: auto;
  }

  &__link:hover {
    background-color: var(--sem-color-background-light);
    color: var(--sem-color-text-darkest);
  }

  &__icon,
  &__label {
    white-space: nowrap;
    line-height: 1;
  }
}
</style>
