<script>
import { directive as clickaway } from "vue-clickaway";

export default {
  name: "ModalTwo",
  directives: {
    clickaway
  },
  props: {
    value: Boolean,
    size: {
      type: String,
      default: "medium",
      validator: value => ["large", "medium", "small"].indexOf(value) !== -1
    },
    closeOnClickaway: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onClickAway() {
      if (this.closeOnClickaway) {
        this.$emit("input", false);
      }
    }
  }
};
</script>

<template>
  <transition name="suaw-modal__fade">
    <div v-if="value" class="suaw-modal">
      <div class="suaw-modal__overlay"></div>
      <div class="suaw-modal__content" :class="'suaw-modal__content--' + size" v-clickaway="onClickAway">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.suaw-modal {
  z-index: 99998;

  &,
  &__overlay {
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
  }

  &__overlay {
    background-color: rgba(10, 10, 10, 0.86);
  }

  &__content {
    background-color: var(--sem-color-background-lightest);
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    box-shadow: var(--soft-shadow-draft);
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-triple);
    left: 0;
    margin: auto;
    right: 0;
    padding: var(--sem-space-quintuple);
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    min-width: 320px;
    max-width: 100%;

    @media screen and (max-width: 650px) {
      width: 90% !important; // Override size-specific widths
    }
  }

  &__content--medium {
    align-items: center;
    text-align: center;
    width: 50%;
    max-width: 500px;
  }

  &__content--small {
    width: 25%;
    max-width: 300px;
  }

  &__content--large {
    width: 75%;
  }

  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 0.2s linear;
  }

  &__fade-enter,
  &__fade-leave-to {
    opacity: 0;
  }
}
</style>
