<script>
import Logo from "../Logo/Logo.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Button from "../Button/Button.vue";
import Sidepanel from '../Sidepanel/Sidepanel.vue';
import MobileHeader from "./MobileHeader.vue";
export default {
  name: "HeaderTwo",
  components: {
    Logo,
    Dropdown,
    Button,
    Sidepanel,
    MobileHeader
  },
  props: {
    type: {
      type: String,
      default: "default",
      validator: function (value) {
        return [
          "default",
          "shadow",
        ].indexOf(value) !== -1;
      },
    },
    navLinks: {
      type: Array,
      default: () => [
        { text: "About", url: "/about/suaw" },
        { text: "Online Events", url: "/online-events" },
        { text: "In-Person Events", url: "/chapters" },
        { text: "Articles", url: "/resources/articles" },
        { text: "Store", url: "https://store.shutupwrite.com/", external: true },
      ],
    },
    mobileNavLinks: {
      type: Array,
      default: () => [
        { text: "Online Events", url: "/online-events" },
        { text: "In-Person Events", url: "/chapters" },
        { text: "Articles", url: "/resources/articles" },
        { text: "Store", url: "https://store.shutupwrite.com/", external: true },
      ],
    },
    authenticatedUserLinks: {
      type: Array,
      default: () => [
        { icon: "IconCalendar", text: "My Events", url: "/dashboard" },
        { icon: "IconUserCircle", text: "Profile", url: "/dashboard/profile" },
        { icon: "IconMail", text: "Inbox", url: "/dashboard/mail" },
        { icon: "IconNotificationOutline", text: "Notifications", function: () => this.openNotificationsClicked() },
        { icon: "IconSettingsFuture", text: "Settings", url: "/dashboard/settings" },
        { icon: "IconExit", text: "Log out", function: () => this.logout() }
      ],
    },
    resourceLinks: {
      type: Array,
      default: () => [
        { text: "About Shut Up & Write!", url: "/about/suaw" },
        { text: "Our Method", url: "/about/method" },
        { text: "Our Organization", url: "/about/organizationn" },
        { text: "Our Impact", url: "/about/impact" },
        { text: "Get Involved", url: "/about/get-involved" },
        { text: "Code of Conduct", url: "/resources/code-of-conduct" },
        { text: "FAQ", url: "/about/faq" },
      ],
    },
    className: {
      type: String,
      default: "",
    },
    userName: {
      type: String,
      default: "John Doe",
    },
    isAuthenticated: {
      type: Boolean,
      default: false
    },
    avatarType: {
      type: String,
      default: "blue",
    },
    avatarContent: {
      type: String,
    },
    totalNotifications: {
      type: String,
    },
  },
  computed: {
    iconOpen() {
      return this.showNavbar ? 'IconCloseBig' : '';
    },
    iconClose() {
      return !this.showNavbar ? 'IconHamburger' : '';
    },
    isLoggedIn() {
      return this.isAuthenticated;
    },
    toggleNavbarClasses() {
      return {
        'show-navbar': this.showNavbar
      }
    }
  },
  data() {
    return {
      showNavbar: false,
      dropdownDirection: "right",
    };
  },
  mounted() {
    this.handleResize(); // Set initial direction based on current window size
    window.addEventListener("resize", this.handleResize);
  },
  updated() {
    // Apply or remove overflow style based on showNavbar
    // document.body.style.overflow = this.showNavbar ? "hidden" : "auto";
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    // document.body.style.overflow = "auto";
  },
  methods: {
    toggleNavbarVisibility() {
      this.showNavbar = !this.showNavbar;
    },
    handleResize() {
      this.dropdownDirection = window.innerWidth <= 1024 ? "left" : "right";
    },
    onLogInClick() {
      this.$emit("login-click", this.$route.fullPath);
      this.toggleNavbarVisibility();
    },
    onLogOutClick() {
      this.toggleNavbarVisibility();
    },
  },
};
</script>

<template>
  <header :class="['suaw-header', 'suaw-header--' + type, className]">
    <div class="suaw-header__main-container">
      <a class="suaw-header__logo-link" href="/">
        <Logo type="LogoDefault" class="suaw-header__logo" />
      </a>
      <Button
        type="secondary"
        size="medium"
        :icon-left="iconOpen"
        :icon-right="iconClose"
        @click="toggleNavbarVisibility"
        class="suaw-header__button"
        :key="showNavbar.toString()"
      />
      <transition name="fade">
        <MobileHeader
          v-if="showNavbar"
          class="suaw-header__mobile-navbar"
          :avatar-content="avatarContent"
          :avatar-type="avatarType"
          :nav-links="mobileNavLinks"
          :authenticated-user-links="authenticatedUserLinks"
          :resource-links="resourceLinks"
          :total-notifications="totalNotifications"
          :is-logged-in="isLoggedIn"
          @login-click="onLogInClick"
          @toggle-navbar="showNavbar = false"
        />
      </transition>
      <nav class="suaw-header__navbar">
        <a
          v-for="(link, index) in navLinks"
          :key="index"
          :href="link.url"
          class="suaw-header__link"
          :target="link.external ? '_blank' : '_self'"
        >
          {{ link.text }}
        </a>
        <a
          v-if="!isLoggedIn"
          href="#"
          class="suaw-header__link"
          @click.prevent="onLogInClick"
        >
          Log In
        </a>
        <Dropdown
          v-if="isLoggedIn"
          :label="userName"
          :avatar-content="avatarContent"
          :avatar-type="avatarType"
          button-class-name="suaw-dropdown__button--header suaw-dropdown__button--no-pd"
          class="suaw-header__dropdown--no-pd"
          button-type="secondary-ghost"
          :items="authenticatedUserLinks"
          :total-notifications="totalNotifications"
          :direction="dropdownDirection"
          @item-click="toggleNavbarVisibility"
        />
      </nav>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.suaw-header {
  @media screen and (min-width: 1025px) {
    --comp-header-navbar-visibility: flex;
    --comp-header-navbar-direction: row;
    --comp-header-navbar-alignment: center;
    --comp-header-navbar-width: auto;
    --comp-header-button-visibility: none;
    --comp-header-logo-height: 56px;
    --comp-header-logo-width: 132px;
    --comp-header-navbar-margin-top: 0;
  }

  @media screen and (max-width: 1024px) {
    --comp-header-navbar-visibility: none;
    --comp-header-navbar-direction: column;
    --comp-header-navbar-alignment: flex-start;
    --comp-header-navbar-width: 100%;
    --comp-header-button-visibility: flex;
    --comp-header-logo-height: 34px;
    --comp-header-logo-width: 80px;
    --comp-header-navbar-margin-top: 1rem;
  }

  background-color: var(--sem-color-background-lightest);
  padding: var(--sem-space-triple);
  position: relative;
  width: 100%;
  z-index: 995;

  @media (max-width: 767px) {
    padding: var(--sem-space-triple) var(--sem-space-double); // Override padding for mobile view
  }

  &--default {
    border-bottom: 1px solid var(--sem-color-border-regular);
  }

  &--shadow {
    box-shadow: var(--soft-shadow-draft);
  }

  &__main-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    max-width: 1120px;
  }

  &__logo-link {
    display: flex;
    transition: opacity, .25s linear;
  }

  &__logo-link:hover {
    opacity: .75;
  }

  &__logo {
    height: var(--comp-header-logo-height);
    width: var(--comp-header-logo-width);
  }

  &__button {
    display: var(--comp-header-button-visibility);
  }

  &__mobile-navbar {
    display: var(--comp-header-button-visibility);
  }

  &__navbar {
    align-items: var(--comp-header-navbar-alignment);
    flex-direction: var(--comp-header-navbar-direction);
    display: var(--comp-header-navbar-visibility);
    gap: var(--sem-space-triple);
    position: relative;
    width: var(--comp-header-navbar-width);
  }

  &__link {
    color: var(--sem-color-text-regular);
    font-family: var(--button-bold-font-family);
    font-size: var(--button-bold-font-size);
    font-style: var(--button-bold-font-style);
    font-weight: var(--button-bold-font-weight);
    letter-spacing: var(--button-bold-letter-spacing);
    line-height: var(--button-bold-line-height);
    position: relative;
    text-decoration: none;
    transition: color, .25s linear;
    white-space: nowrap;
  }

  &__link:hover {
    color: var(--sem-color-text-darkest);
  }
}
</style>
