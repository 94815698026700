<script>
export default {
  name: "ListSection",
  props: {
    listDirection: {
      type: String,
      default: "vertical",
      validator: value => ["horizontal", "vertical"].includes(value)
    }
  },
  computed: {
    listClass() {
      return this.listDirection === "horizontal" ? "suaw-list-section__list--horizontal" : "suaw-list-section__list--vertical";
    }
  }
};
</script>

<template>
  <div class="suaw-list-section">
    <ul :class="['suaw-list-section__list', listClass]">
      <slot />
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.suaw-list-section {
  width: 100%;
  &__list {
    display: flex;
    width: 100%;
    padding: 0; // Remove default padding
    margin: 0; // Remove default margin
    list-style-type: none; // Removes bullets for list items
    &--vertical {
      flex-direction: column;
      gap: var(--sem-space-double);
    }
    &--horizontal {
      flex-direction: row;
      gap: var(--sem-space-triple);
      overflow-x: auto; // Enable horizontal scrolling
      white-space: nowrap; // Prevent wrapping
      width: calc(100% + 16px); // Extend the width beyond the viewport
      margin: 0 -8px; // Add negative margins to make room for shadows
      padding: 8px; // Add padding on both sides to keep alignment
    }
  }
}
</style>
