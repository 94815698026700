<script>
import Label from "../Label/Label.vue";
import Button from "../Button/Button.vue";
import Divider from "../Divider/Divider.vue";
import Icon from "../Icon/Icon.vue";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";

class FileHandler {
  constructor({ editor, acceptedImageFileTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"] } = {}) {
    this.editor = editor;
    this.acceptedImageFileTypes = acceptedImageFileTypes;
  }

  accepts(file) {
    return this.acceptedImageFileTypes.includes(file.type);
  }
}

export default {
  name: "TextArea",
  components: {
    Label,
    Button,
    EditorContent,
    Divider,
    Icon
  },
  props: {
    value: {
      type: [Object, String],
      default: () => ({ type: "doc", content: [{ type: "paragraph" }] })
    },
    allowImages: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Type here..."
    },
    borderStyle: {
      type: String,
      default: "default",
      validator: function(value) {
        return ["default", "ghost"].indexOf(value) !== -1;
      }
    },
    label: {
      type: String,
      default: ""
    },
    labelWeight: {
      type: String,
      default: "bold",
      validator: function(value) {
        return ["normal", "bold"].indexOf(value) !== -1;
      },
    },
    id: {
      type: String,
      default: "textArea"
    },
    className: {
      type: String,
      default: ""
    },
    useTipTap: {
      type: Boolean,
      default: false
    },
    useFormatting: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: "This field is required"
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    canResize: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editor: null,
      isContentEdited: false,
      fileHandler: null
    };
  },
  watch: {
    value(value) {
      if (this.useTipTap && this.editor) {
        const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value);
        if (isSame) {
          return;
        }
        this.editor.commands.setContent(value, false);
      }
    },
  },
  mounted() {
    if (this.useTipTap) {
      this.initializeEditor();
      if (this.allowImages) {
        this.fileHandler = new FileHandler({ editor: this.editor });
      }
    }
    this.updateSize();
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
  methods: {
    initializeEditor() {
      const extensions = [
        StarterKit.configure({
          image: false // Disable StarterKit's image handling
        }),
        Underline,
        Placeholder.configure({ placeholder: this.placeholder }),
        Image.configure({
          inline: true,
          allowBase64: true
        })
      ];

      this.editor = new Editor({
        content: this.value,
        extensions,
        onUpdate: () => {
          if (this.editor) {
            this.$emit("input", this.editor.getJSON());
          }
        },
        editorProps: this.allowImages ? {
          handleDrop: (view, event, slice, moved) => {
            if (!moved && event.dataTransfer?.files?.length) {
              const files = Array.from(event.dataTransfer.files);
              const images = files.filter(file => this.fileHandler.accepts(file));

              if (images.length > 0) {
                event.preventDefault();
                this.uploadFiles(images);
                return true;
              }
            }
            return false;
          },
          handlePaste: (view, event) => {
            if (event.clipboardData?.files?.length) {
              const files = Array.from(event.clipboardData.files);
              const images = files.filter(file => this.fileHandler.accepts(file));

              if (images.length > 0) {
                event.preventDefault();
                this.uploadFiles(images);
                return true;
              }
            }
            return false;
          }
        } : {},
      });
    },
    async handleFileInput(event) {
      const files = Array.from(event.target.files);
      await this.uploadFiles(files);
      event.target.value = ""; // Reset input
    },
    async uploadFiles(files) {
      for (const file of files) {
        try {
          if (this.fileHandler.accepts(file)) {
            const base64Data = await this.getBase64Data(file);
            this.$emit('image-upload', base64Data);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    },
    async getBase64Data(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    isEmpty() {
      return this.editor
        ? !this.editor.state.doc.textContent.trim().length
        : true;
    },
    defaultEditorContent() {
      console.log("in default editor content")
      return {
        type: "doc",
        content: [{
          type: "paragraph"
        }]
      };
    },
    handleInput(event) {
      if (!this.useTipTap) {
        this.$emit("input", event.target.value);
      }
    },
    updateSize() {
      this.$nextTick(() => {
        if (this.useTipTap || !this.$refs.textarea) {
          return;
        }
        const textarea = this.$refs.textarea;
        textarea.style.height = "auto"; // Reset height to auto
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scroll height
      });
    },
  },
};
</script>

<template>
  <div class="suaw-textarea">
    <Label
      v-if="label"
      :labelText="label"
      :labelFor="id"
      :weight="labelWeight"
      size="small"
    />
    <div
      v-if="useFormatting"
      class="toolbar suaw-textarea__toolbar"
    >
      <Button
        class="suaw-textarea__toolbar-button"
        type="ghost"
        iconLeft="IconUndo"
        size="small"
        button-text=""
        @click="editor?.chain().focus().undo().run()"
      />
      <Button
        class="suaw-textarea__toolbar-button"
        type="ghost"
        iconLeft="IconRedo"
        size="small"
        button-text=""
        @click="editor?.chain().focus().redo().run()"
      />
      <Button
        class="suaw-textarea__toolbar-button"
        :type="editor?.isActive('bold') ? 'ghost-active' : 'ghost'"
        iconLeft="IconBold"
        size="small"
        button-text=""
        @click="editor?.chain().focus().toggleBold().run()"
      />
      <Button
        class="suaw-textarea__toolbar-button"
        :type="editor?.isActive('italic') ? 'ghost-active' : 'ghost'"
        iconLeft="IconItalic"
        size="small"
        button-text=""
        @click="editor?.chain().focus().toggleItalic().run()"
      />
      <Button
        class="suaw-textarea__toolbar-button"
        :type="editor?.isActive('underline') ? 'ghost-active' : 'ghost'"
        iconLeft="IconUnderline"
        size="small"
        button-text=""
        @click="editor?.chain().focus().toggleUnderline().run()"
      />
      <Button
        class="suaw-textarea__toolbar-button"
        :type="editor?.isActive('bulletList') ? 'ghost-active' : 'ghost'"
        iconLeft="IconListUl"
        size="small"
        button-text=""
        @click="editor?.chain().focus().toggleBulletList().run()"
      />
      <Button
        class="suaw-textarea__toolbar-button"
        :type="editor?.isActive('orderedList') ? 'ghost-active' : 'ghost'"
        iconLeft="IconListOl"
        size="small"
        button-text=""
        @click="editor?.chain().focus().toggleOrderedList().run()"
      />
      <input
        v-if="allowImages"
        ref="fileInput"
        type="file"
        accept="image/*"
        style="display: none"
        @change="handleFileInput"
      />
      <Button
        v-if="allowImages"
        type="ghost"
        iconLeft="IconImageAlt"
        size="small"
        button-text=""
        @click="$refs.fileInput.click()"
      />
    </div>
    <Divider v-if="useFormatting" />
    <div class="suaw-textarea__container">
      <editor-content
        v-if="useTipTap"
        ref="editorContent"
        :editor="editor"
        :class="[
          'suaw-textarea__field',
          'suaw-textarea--' + borderStyle,
          { 'suaw-textarea--resizable': canResize },
          className
        ]"
      />
      <textarea
        v-else
        class="suaw-textarea__field"
        :id="id"
        ref="textarea"
        :value="value"
        @input="handleInput"
        :placeholder="placeholder"
        :class="[
          'suaw-textarea--' + borderStyle,
          { 'suaw-textarea--resizable': canResize },
          className
        ]"
      />
      <div 
        v-if="canResize"
        class="suaw-textarea__resize-handle"
      >
        <Icon icon="IconUnfoldMore" size="medium" class="suaw-textarea__resize-icon" />
      </div>
    </div>
    <small
      v-if="isRequired"
      class="suaw-textarea__message"
    >
      {{ errorMessage }}
    </small>
  </div>
</template>

<style scoped lang="scss">
.suaw-textarea {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-double);
  width: 100%;

  &__container {
    position: relative;
    width: 100%;
  }

  &__field {
    border: 1px solid var(--sem-color-info-light);
    background-color: var(--sem-color-background-lightest);
    font-family: var(--font-family);
    font-size: var(--body-copy-font-size);
    min-height: 100px;
    width: 100%;
    overflow: auto;
  }

  &__field:focus-visible {
    outline: none;
  }

  &__toolbar {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    max-width: 100%;

    &-button {
      width: 24px;
    }
  }

  &--default {
    border-radius: var(--sem-radius-sm);
    border: 1px solid var(--sem-color-border-medium);
    padding: var(--sem-space-base);
  }

  &--default:focus {
    border-color: var(--sem-color-info-medium);
  }

  &--ghost {
    border: 0;
    padding: 0 var(--sem-space-base);
  }

  &:focus {
    outline: none;
  }

  &__message {
    color: var(--sem-color-critical-medium);
  }

  &__resize-handle {
    position: absolute;
    bottom: -13px;
    right: -3px;
    color: var(--sem-color-border-medium);
    z-index: 1;
    pointer-events: none;
  }

  &--resizable {
    resize: vertical;
    -webkit-resize: vertical;
    -moz-resize: vertical;
    overflow: auto;
    padding-bottom: var(--sem-space-base);
    height: fit-content;
    min-height: fit-content;
  }

  ::-webkit-resizer {
    display: none;
  }

  ::-moz-resizer {
    display: none;
  }

  ::resize-handle {
    display: none;
  }

  ::v-deep {
    .ProseMirror {
      background-color: var(--sem-color-background-lightest);
      min-height: 100px;
      overflow: auto;

      h1 {
        font-size: var(--h-1-font-size);
        font-weight: var(--h-1-font-weight);
      }
      h2 {
        font-size: var(--h-2-font-size);
        font-weight: var(--h-2-font-weight);
      }
      h3 {
        font-size: var(--h-3-font-size);
        font-weight: var(--h-3-font-weight);
      }
      blockquote {
        background: rgb(255, 255, 235);
        border-color: var(--sem-color-primary-medium);
        border-style: solid;
        border-width: 1px 1px 1px 16px;
        color: var(--sem-color-text-medium);
        font-size: var(--p-font-size);
        margin: 0.5em auto;
        padding: 1rem;
        width: 90%;
      }
      &-focused {
        outline: none;
      }
      p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: var(--gl-color-gray-500);
        pointer-events: none;
        height: 0;
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>
