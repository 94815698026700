<script>
import Chip from "../Chip/Chip.vue";

export default {
  name: "LikeButtonView",
  components: {
    Chip
  },
  props: {
    size: {
      type: String,
      default: "medium"
    },
    likeCount: {
      type: Number,
      default: 0
    },
    isLiked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    likeLabel() {
      if (!this.likeCount) {
        return "";
      }
      return `${this.likeCount} Like${this.likeCount !== 1 ? "s" : ""}`;
    },
    iconName() {
      return this.isLiked ? "IconHeartFill" : "IconHeartOutline";
    }
  },
  methods: {
    handleLikeClick() {
      this.$emit("click");
    }
  }
};
</script>

<template>
  <Chip
    :key="iconName"
    :size="size"
    chip-type="icon"
    status="critical"
    :icon="iconName"
    shape="square"
    class="suaw-chip--clickable"
    :label="likeLabel"
    @click="handleLikeClick"
  />
</template>
