<script>
import Label from "../Label/Label.vue";
import Button from "../Button/Button.vue";
export default {
  name: "GridSection",
  components: { Label, Button },
  props: {
    showHeader: {
      type: Boolean,
      default: false
    },
    headerLabel: {
      type: String,
      default: ""
    },
    showLinkButton: {
      type: Boolean,
      default: false
    },
    linkButtonLabel: {
      type: String,
      default: "View All"
    },
    maxColumnsPerRow: {
      type: Number,
      default: 3 //cannot go above 4 unless changing the min pixel width in grid-template-columns
    }
  }
};
</script>

<template>
  <div class="suaw-grid-section" :style="{ '--max-cols': maxColumnsPerRow + 1 }">
    <div v-if="showHeader" class="suaw-grid-section__header">
      <Label size="extra-large" :label-text="headerLabel" />
      <Button
        v-if="showLinkButton"
        class="suaw-grid-section__header-button suaw-button--no-y-pd suaw-button--no-x-pd"
        size="hero"
        type="ghost"
        :button-text="linkButtonLabel"
        @click="$emit('profile-click', userId)"
      />
    </div>
    <div class="suaw-grid-section__grid">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-grid-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__header-button {
    height: auto;
  }
  &__grid {
    width: 100%;
    display: grid;
    column-gap: var(--sem-space-triple);
    row-gap: var(--sem-space-quadruple);
    grid-template-columns: repeat(auto-fill, minmax(max(275px, 100% / var(--max-cols)), 1fr));
  }
}
</style>
