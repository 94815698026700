<script>
export default {
  name: "AvatarView",
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium",
      validator: function(value) {
        return ["huge", "large", "medium", "small"].includes(value);
      }
    },
    displayName: {
      type: String,
      default: null
    },
    initials: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: "",
      validator: function(value) {
        return [
          "",
          "green",
          "light-green",
          "blue",
          "light-blue",
          "cyan",
          "light-cyan",
          "purple",
          "light-purple",
          "orange",
          "light-orange",
          "red",
          "light-red",
          "light-gray"
        ].includes(value);
      }
    },
    isAvatarHidden: {
      type: Boolean,
      default: false
    },
    isDeactivated: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    hasImage() {
      return !this.isAvatarHidden && !!this.url;
    },
    avatarColor() {
      return this.isDeactivated ? "light-gray" : this.color === "" ? "blue" : this.color;
    },
    avatarInitials() {
      return this.isDeactivated ? "" : this.initials;
    }
  }
};
</script>

<template>
  <span class="suaw-avatar" :class="['suaw-avatar--' + avatarColor, 'suaw-avatar--' + size, className]">
    <img v-if="!isLoading && hasImage" class="suaw-avatar__image" :src="url" :alt="`Avatar of ${displayName}`" />
    <p v-else class="suaw-avatar__text">{{ avatarInitials }}</p>
  </span>
</template>

<style lang="scss" scoped>
.suaw-avatar {
  border-radius: var(--sem-radius-max);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &--huge {
    height: 112px;
    min-width: 112px;
    width: 112px;
  }

  &--large {
    height: 56px;
    min-width: 56px;
    width: 56px;
  }

  &--medium {
    height: 40px;
    min-width: 40px;
    width: 40px;
  }

  &--small {
    height: 25.5px;
    min-width: 25.5px;
    width: 25.5px;
  }

  &--green {
    background-color: var(--sem-color-success-medium);
  }

  &--light-green {
    background-color: var(--sem-color-success-light);
  }

  &--blue {
    background-color: var(--sem-color-info-medium);
  }

  &--light-blue {
    background-color: var(--sem-color-info-light);
  }

  &--cyan {
    background-color: var(--sem-color-accent-cyan);
  }

  &--light-cyan {
    background-color: var(--sem-color-decorative-cyan);
  }

  &--purple {
    background-color: var(--sem-color-accent-purple);
  }

  &--light-purple {
    background-color: var(--sem-color-decorative-purple);
  }

  &--orange {
    background-color: var(--sem-color-accent-orange);
  }

  &--light-orange {
    background-color: var(--sem-color-decorative-orange);
  }

  &--red {
    background-color: var(--sem-color-critical-medium);
  }

  &--light-red {
    background-color: var(--sem-color-critical-light);
  }

  &--light-gray {
    background-color: var(--sem-color-neutral-light);
  }

  &__image {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  &__text {
    font-family: var(--h-4-font-family);
    font-style: var(--h-4-font-style);
    font-weight: var(--h-4-font-weight);
    letter-spacing: var(--h-4-letter-spacing);
    line-height: var(--h-4-line-height);
    text-align: center;
    white-space: nowrap;

    .suaw-avatar--huge & {
      font-size: 48px;
    }
    .suaw-avatar--large & {
      font-size: 20px;
    }

    .suaw-avatar--medium & {
      font-size: 16px;
    }

    .suaw-avatar--small & {
      font-size: 10px;
    }

    .suaw-avatar--green & {
      color: var(--sem-color-success-light);
    }

    .suaw-avatar--light-green & {
      color: var(--sem-color-success-medium);
    }

    .suaw-avatar--blue & {
      color: var(--sem-color-info-light);
    }

    .suaw-avatar--light-blue & {
      color: var(--sem-color-info-medium);
    }

    .suaw-avatar--cyan & {
      color: var(--sem-color-decorative-cyan);
    }

    .suaw-avatar--light-cyan & {
      color: var(--sem-color-accent-cyan);
    }

    .suaw-avatar--purple & {
      color: var(--sem-color-decorative-purple);
    }

    .suaw-avatar--light-purple & {
      color: var(--sem-color-accent-purple);
    }

    .suaw-avatar--orange & {
      color: var(--sem-color-decorative-orange);
    }
    .suaw-avatar--light-orange & {
      color: var(--sem-color-accent-orange);
    }

    .suaw-avatar--red & {
      color: var(--sem-color-critical-light);
    }

    .suaw-avatar--light-red & {
      color: var(--sem-color-critical-medium);
    }

    .suaw-avatar--light-gray & {
      color: var(--sem-color-text-darken);
    }
  }
}
</style>
