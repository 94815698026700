<script>
import List from "../List/List.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Card from "../Card/Card.vue";
import Link from "../Link/Link.vue";
import Thumbnail from "../Thumbnail/Thumbnail.vue";

export default {
  name: "Sidebar",
  components: {
    List,
    Paragraph,
    Card,
    Link,
    Thumbnail,
  },
  props: {
    sidebarItems: {
      type: Array,
    },
  },
};
</script>

<template>
  <aside class="suaw-sidebar">
    <div
      v-for="(item, index) in sidebarItems"
      :key="index"
      class="suaw-sidebar__block"
    >
      <Paragraph
        v-if="item.label"
        class="suaw-sidebar__label"
        weight="bold"
        size="medium"
        :text="item.label"
      />
      <component
        :is="item.component"
        v-bind="item.props"
      />
    </div>
    <slot />
  </aside>
</template>

<style lang="scss" scoped>
.suaw-sidebar {
  @media screen and (min-width: 1025px) {
    --comp-sidebar-width: 320px;
  }

  @media screen and (max-width: 1024px) {
    --comp-sidebar-width: 100%;
  }

  gap: var(--sem-space-quadruple);
  min-width: var(--comp-sidebar-width);
  width: var(--comp-sidebar-width);

  &,
  &__block {
    display: flex;
    flex-direction: column;
  }

  &__label {
    display: block;
    margin-bottom: var(--sem-space-double);
  }
}
</style>
