import Vue from "vue";

export const loadingState = Vue.observable({
  isLoading: true
});

export const setLoading = value => {
  loadingState.isLoading = value;
};

export const useLoading = () => {
  return {
    isLoading: loadingState,
    setLoading
  };
};
