<script>
import Button from '../Button/Button.vue';
import Pill from '../Pill/Pill.vue';
import Icon from '../Icon/Icon.vue';
export default {
  name: "MobileHeader",
  components: { Button, Pill, Icon },
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    totalNotifications: {
      type: String,
    },
    eventLinks: {
      type: Array,
      default: () => [
        { text: "In-Person Events", url: "/chapters" },
        { text: "Online Events", url: "/online-events" },
      ],
    },
    aboutLinks: {
      type: Array,
      default: () => [
        { text: "About Shut Up & Write!", url: "/about/about-suaw" },
        { text: "Our Method", url: "/about/method" },
        { text: "Our Organization", url: "/about/organization" },
        { text: "Our Impact", url: "/about/impact" },
        { text: "Get Involved", url: "/about/get-involved" },
        { text: "Code of Conduct", url: "/resources/code-of-conduct" },
        { text: "FAQ", url: "/about/faq" },
      ],
    },
    resourceLinks: {
      type: Array,
      // default: () => [
      //   { text: "Articles", url: "/resources/articles" },
      //   { text: "Become an Organizer", url: "/resources/become-an-organizer" },
      //   { text: "Organizer Handbook", url: "/resources/organizer/organizer-handbook" },
      //   { text: "Organizer FAQ", url: "/resources/organizer/organizer-faq" },
      // ],
    },
    navLinks: {
      type: Array,
    },
    authenticatedUserLinks: {
      type: Array,
      default: () => [
        { icon: "IconCalendar", text: "My Events", url: "/dashboard" },
        { icon: "IconUserCircle", text: "Profile", url: "/dashboard/profile" },
        { icon: "IconMail", text: "Inbox", url: "/dashboard/mail" },
        { icon: "IconNotificationOutline", text: "Notifications", function: () => this.openNotificationsClicked() },
        { icon: "IconSettingsFuture", text: "Settings", url: "/dashboard/settings" },
        { icon: "IconExit", text: "Log out", function: () => this.logout() },
      ],
    },
  },
  data() {
    return {
      showAuthenticatedLinks: false,
      showResourceLinks: false,
      showEventLinks: false,
      showAboutLinks: false,
    };
  },
  methods: {
    toggleAuthenticatedLinks() {
      this.showAuthenticatedLinks = !this.showAuthenticatedLinks;
    },
    toggleResourceLinks() {
      this.showResourceLinks = !this.showResourceLinks;
    },
    toggleEventLinks() {
      this.showEventLinks = !this.showEventLinks;
    },
    toggleAboutLinks() {
      this.showAboutLinks = !this.showAboutLinks;
    },
    onLogInClick() {
      this.$emit("login-click");
    },
    handleLinkAction(link) {
      if (typeof link.function === "function") {
        link.function(); // Execute the function if it exists
      }
      if (link.text === "Log out") {
        setTimeout(() => {
          this.$emit("toggle-navbar"); // Close the navbar after a 500 ms delay
        }, 500);
      } else {
        this.$emit("toggle-navbar"); // Close the navbar afterward
      }
    },
  },
};
</script>

<template>
  <nav class="suaw-mobile-navbar">
    <!-- Main menu transition (slides left out and in) -->
    <transition name="slide-left">
      <ul
        v-if="
          !showAuthenticatedLinks &&
          !showResourceLinks &&
          !showEventLinks &&
          !showAboutLinks
        "
        class="suaw-mobile-navbar__list"
      >
        <li>
          <a
            v-if="!isAuthenticated"
            href="#"
            class="suaw-mobile-navbar__link"
            @click.prevent="onLogInClick"
          >
            Log In
          </a>
          <div v-else class="suaw-mobile-navbar__me-container">
            <Button
              class="suaw-mobile-navbar__button"
              button-text="Me"
              size="mobile-nav"
              type="secondary-ghost"
              :show-avatar="showAvatar"
              @click="toggleAuthenticatedLinks"
            >
              <Pill
                v-if="totalNotifications"
                :text="totalNotifications"
                status="critical"
                class="suaw-mobile-navbar__pill"
              />
              <Icon
                class="suaw-mobile-navbar__icon"
                icon="IconChevronBigRight"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li>
          <div class="suaw-mobile-navbar__me-container">
            <Button
              class="suaw-mobile-navbar__button"
              button-text="Events"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleEventLinks"
            >
              <Icon
                class="suaw-mobile-navbar__icon"
                icon="IconChevronBigRight"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li>
          <div class="suaw-mobile-navbar__me-container">
            <Button
              class="suaw-mobile-navbar__button"
              button-text="About"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleAboutLinks"
            >
              <Icon
                class="suaw-mobile-navbar__icon"
                icon="IconChevronBigRight"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li v-if="resourceLinks">
          <div class="suaw-mobile-navbar__me-container">
            <Button
              class="suaw-mobile-navbar__button"
              button-text="Resources"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleResourceLinks"
            >
              <Icon
                class="suaw-mobile-navbar__icon"
                icon="IconChevronBigRight"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li v-for="(link, index) in navLinks" :key="index">
          <a
            :href="link.url"
            class="suaw-mobile-navbar__link"
            :target="link.external ? '_blank' : '_self'"
          >
            {{ link.text }}
          </a>
        </li>
      </ul>
    </transition>

    <!-- Authenticated menu transition (slides right out and in) -->
    <transition name="slide-right">
      <ul v-if="showAuthenticatedLinks" class="suaw-mobile-navbar__list">
        <li>
          <div class="suaw-mobile-navbar__me-container">
            <Button 
              class="suaw-mobile-navbar__button"
              button-text="Back"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleAuthenticatedLinks"
            >
              <Icon
                :key="showAuthenticatedLinks"
                class="suaw-mobile-navbar__back-icon"
                icon="IconChevronBigLeft"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li v-for="(link, index) in authenticatedUserLinks" :key="index" class="suaw-mobile-navbar__link">
          <!-- <Icon :icon="link.icon" size="medium" class="suaw-mobile-navbar__auth-icon" /> -->
          <a v-if="link.url" :href="link.url" class="suaw-mobile-navbar__link">{{ link.text }}</a>
          <Button
            v-else
            class="suaw-mobile-navbar__button"
            :button-text="link.text"
            size="mobile-nav"
            type="secondary-ghost"
            @click="handleLinkAction(link)"
          />
          <!-- <a v-else href="#" class="suaw-mobile-navbar__link" @click.prevent="link.text === 'Log out' ? handleLogout(link.function) : link.function">{{ link.text }}</a> -->
          <!-- <a v-else href="#" class="suaw-mobile-navbar__link" @click.prevent="handleLinkAction(link.function)">{{ link.text }}</a> -->
          <Pill
            v-if="link.notificationCounter"
            :text="link.notificationCounter"
            status="critical"
            class="suaw-mobile-navbar__notification-pill"
          />
        </li>
      </ul>
    </transition>

    <transition name="slide-right">
      <ul v-if="showAboutLinks" class="suaw-mobile-navbar__list">
        <li>
          <div class="suaw-mobile-navbar__me-container">
            <Button 
              class="suaw-mobile-navbar__button"
              button-text="Back"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleAboutLinks"
            >
              <Icon
                :key="showAboutLinks"
                class="suaw-mobile-navbar__back-icon"
                icon="IconChevronBigLeft"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li v-for="(link, index) in aboutLinks" :key="index" class="suaw-mobile-navbar__link">
          <a :href="link.url" class="suaw-mobile-navbar__link">{{ link.text }}</a>
        </li>
      </ul>
    </transition>

    <transition name="slide-right">
      <ul v-if="showEventLinks" class="suaw-mobile-navbar__list">
        <li>
          <div class="suaw-mobile-navbar__me-container">
            <Button 
              class="suaw-mobile-navbar__button"
              button-text="Back"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleEventLinks"
            >
              <Icon
                :key="showEventLinks"
                class="suaw-mobile-navbar__back-icon"
                icon="IconChevronBigLeft"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li v-for="(link, index) in eventLinks" :key="index" class="suaw-mobile-navbar__link">
          <a :href="link.url" class="suaw-mobile-navbar__link">{{ link.text }}</a>
        </li>
      </ul>
    </transition>

    <!-- Resources menu transition (slides right out and in) -->
    <transition name="slide-right">
      <ul v-if="showResourceLinks" class="suaw-mobile-navbar__list">
        <li>
          <div class="suaw-mobile-navbar__me-container">
            <Button 
              class="suaw-mobile-navbar__button"
              button-text="Back"
              size="mobile-nav"
              type="secondary-ghost"
              @click="toggleResourceLinks"
            >
              <Icon
                :key="showResourceLinks"
                class="suaw-mobile-navbar__back-icon"
                icon="IconChevronBigLeft"
                size="nav"
                color="gray"
              />
            </Button>
          </div>
        </li>
        <li v-for="(link, index) in resourceLinks" :key="index" class="suaw-mobile-navbar__link">
          <!-- <Icon :icon="link.icon" size="medium" class="suaw-mobile-navbar__auth-icon" /> -->
          <a v-if="link.url" :href="link.url" class="suaw-mobile-navbar__link">{{ link.text }}</a>
          <a v-else href="#" class="suaw-mobile-navbar__link" @click.prevent="link.function">{{ link.text }}</a>
        </li>
      </ul>
    </transition>
  </nav>
</template>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss" scoped>
.suaw-mobile-navbar {
  position: static;
  // top: 88px;
  // left: 0;
  width: 100vw;
  min-height: 100vh;
  // overflow-y: auto;
  background-color: var(--sem-color-background-lightest); // Optional: set a background color
  // z-index: 11;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &__list {
    list-style: none; // Remove default bullet points
    padding: 0; // Remove default padding
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  &__link {
    list-style: none;
    color: var(--sem-color-text-regular);
    font-family: var(--button-bold-font-family);
    font-size: 32px;
    font-style: var(--button-bold-font-style);
    font-weight: var(--button-bold-font-weight);
    letter-spacing: var(--button-bold-letter-spacing);
    line-height: var(--button-bold-line-height);
    position: relative;
    text-decoration: none;
    transition: color, .25s linear;
    white-space: nowrap;
  }
  // &__link:hover {
  //   color: var(--sem-color-text-darkest);
  // }
  &__me-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__button {
    position: relative;
  }
  &__pill {
    top: 25px;
    right: -5px;
    position: absolute;
  }
  &__notification-pill {
    top: 15px;
    right: -25px;
    position: absolute;
  }
  &__icon {
    top: -1px;
    right: -43px;
    position: absolute;
  }
  &__back-icon {
    top: -1px;
    right: 65px;
    position: absolute;
  }
  /* Slide left transition */
  .slide-left-enter-active, .slide-left-leave-active {
    transition: transform 0.5s ease;
    position: absolute;
    width: 100%;
  }
  .slide-left-enter {
    transform: translateX(-100%); /* Start off-screen to the left */
  }
  .slide-left-leave-to {
    transform: translateX(-100%); /* Slide out to the left */
  }
  .slide-left-enter-to {
    transform: translateX(0); /* Slide to center */
  }

  /* Slide right transition */
  .slide-right-enter-active, .slide-right-leave-active {
    transition: transform 0.5s ease;
    position: absolute;
    width: 100%;
  }
  .slide-right-enter {
    transform: translateX(100%); /* Start off-screen to the right */
  }
  .slide-right-leave-to {
    transform: translateX(100%); /* Slide out to the right */
  }
  .slide-right-enter-to {
    transform: translateX(0); /* Slide to center */
  }
}
</style>
