<script>
import Chip from "../Chip/Chip.vue";
import AvatarChipPipe from "../Avatar/AvatarChipPipe.vue";
import Paragraph from "../Paragraph/Paragraph.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import TextArea from "../TextArea/TextArea.vue";
import Button from "../Button/Button.vue";
import EditorBox from "../EditorBox/EditorBox.vue";

export default {
  name: "DiscussionDetails",
  components: {
    Chip,
    AvatarChipPipe,
    Paragraph,
    Dropdown,
    TextArea,
    Button,
    EditorBox,
  },
  props: {
    id: {
      type: String,
    },
    replyPlaceholder: {
      type: String,
      default: "Type your reply here...",
    },
    replyButtonText: {
      type: String,
      default: "Reply",
    },
    headingText: {
      type: [Object, String]
    },
    posted: {
      type: String,
    },
    userId: {
      type: String,
      required: true
    },
    showDiscussionLikes: {
      type: Boolean,
      default: false,
    },
    likeCount: {
      type: Number,
    },
    isLiked: {
      type: Boolean,
      default: false,
    },
    dropdownItems: {
      type: Array,
    },
    textBoxPrimaryButton: {
      type: String,
      default: "Confirm",
    },
    textBoxSecondaryButton: {
      type: String,
      default: "Cancel",
    },
    textBoxSize: {
      type: String,
    },
  },
  watch: {
    headingText(newVal) {
      this.editableText = newVal;
    },
  },
  data() {
    return {
      editableText: this.headingText,
      editMode: false,
      chipSize: "medium",
      discussionLikes: "3",
      userInput: { type: "doc", content: [{ type: "paragraph" }] },
    };
  },
  computed: {
    computedDiscussionLikes() {
      if (!this.likeCount) {
        return "";
      }
      return `${this.likeCount} Like${this.likeCount !== 1 ? "s" : ""}`;
    },
  },
  methods: {
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    handleEditorBoxPrimaryButtonClick() {
      const payload = {
        text: this.editableText,
        id: this.id,
      };
      this.$emit("edit-discussion", payload);
      this.toggleEditMode();
    },
    handleSubmit() {
      if (this.$refs.textArea.isEmpty()) {
        return;
      } else {
        const payload = {
          text: this.userInput,
          parentPostId: this.id,
        };
        this.$emit("submit-response", payload);
        this.userInput = { type: "doc", content: [{ type: "paragraph" }] };
      }
    },
  },
};
</script>

<template>
  <div class="suaw-discussion__content">
    <template v-if="editMode">
      <EditorBox
        v-model="editableText"
        :size="textBoxSize"
        :button-text="textBoxPrimaryButton"
        :secondary-button-text="textBoxSecondaryButton"
        @submit="handleEditorBoxPrimaryButtonClick"
        @secondary-click="toggleEditMode"
      />
    </template>
    <template v-else>
      <Paragraph
        size="large"
        weight="bold"
        :text="headingText"
      />
    </template>

    <footer class="suaw-discussion__footer">
      <div class="suaw-discussion__block">
        <div class="suaw-discussion__status-block">
          <AvatarChipPipe
            :user-id="userId"
            :size="chipSize"
          />
          <span class="suaw-discussion__posted--small">
            {{ posted }}
          </span>
        </div>
        <div class="suaw-discussion__status-block">
          <Chip
            v-if="showDiscussionLikes"
            :key="isLiked"
            :size="chipSize"
            chipType="icon"
            status="critical"
            :icon="isLiked ? 'IconHeartFill' : 'IconHeartOutline'"
            shape="square"
            class="suaw-chip--clickable"
            :label="computedDiscussionLikes"
            @click="$emit('like-click', { id, isLiked })"
          />
          <Dropdown
            iconDropdown="IconMoreHorizontal"
            dropdownButtonSize="small"
            buttonType="secondary-outline"
            buttonClassName="suaw-dropdown__button--chip"
            :items="dropdownItems"
            @editModeToggled="toggleEditMode"
          />
        </div>
      </div>
    </footer>

    <div class="suaw-discussion__textarea">
      <TextArea
        ref="textArea"
        v-model="userInput"
        :placeholder="replyPlaceholder"
        borderStyle="ghost"
        :use-tip-tap="true"
      />
      <Button
        size="small"
        className="suaw-discussion__reply-button"
        type="primary-light"
        :buttonText="replyButtonText"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.suaw-discussion {
  &__content,
  &__footer {
    display: flex;
  }

  &__content {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__footer {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__block {
    display: flex;
    justify-content: space-between;
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__block-reply {
    flex-direction: column;
    position: relative;
  }

  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__discussion-block {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    width: 100%;
  }

  &__textarea {
    border: 1px solid var(--sem-color-border-medium);
    border-radius: var(--sem-radius-sm);
    flex-direction: column;
    padding: var(--sem-space-base);
    position: relative;
  }

  &__reply-button {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}
</style>
