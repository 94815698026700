<script>
import { GetUserAvatar } from "./avatarOperations.gql";
import AvatarChipView from "./AvatarChipView.vue";

export default {
  name: "AvatarChipPipe",
  components: {
    AvatarChipView
  },
  inject: {
    injectedUserId: {
      from: "userId",
      default: null
    }
  },
  props: {
    userId: {
      type: String,
      required: false,
      default: null
    },
    size: {
      type: String,
      default: "medium",
      validator: function(value) {
        return ["medium", "small"].indexOf(value) !== -1;
      }
    },
    color: {
      type: String,
      default: "default",
      validator(value) {
        return ["default", "online", "info", "success", "critical", "none"].indexOf(value) !== -1;
      }
    },
    className: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loadingCount: 0,
      resultUsersView: {
        users_view: []
      }
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    user() {
      return this.resultUsersView.length > 0 ? this.resultUsersView[0] : null;
    },
    effectiveUserId() {
      return this.userId || this.injectedUserId();
    }
  },
  apollo: {
    resultUsersView: {
      query: GetUserAvatar,
      variables() {
        return {
          userId: this.effectiveUserId
        };
      },
      loadingKey: "loadingCount",
      skip() {
        return !this.effectiveUserId;
      }
    }
  }
};
</script>

<template>
  <AvatarChipView v-if="isLoading" :is-avatar-hidden="true" :display-name="'Loading...'" />
  <AvatarChipView
    v-else
    :size="size"
    :color="color"
    :avatar-url="user.avatar_url"
    :avatar-color="user.avatar_color"
    :avatar-initials="user.initials"
    :is-avatar-hidden="user.is_avatar_hidden"
    :is-deactivated="!!user.deleted_at"
    :display-name="user.display_name"
    :class-name="className"
  />
</template>
