<script>
import Chip from "../Chip/Chip.vue";

export default {
  name: "ChipList",
  components: {
    Chip,
  },
  props: {
    chipItems: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<template>
  <div class="suaw-chip-list">
    <Chip
      v-for="(item, index) in chipItems"
      :key="item.id || index"
      shape="square"
      size="medium"
      status="default"
      :icon="item.icon"
      :label="item.label"
      :chipType="item.chipType"
      :avatarType="item.avatarType"
      :avatarContent="item.avatarContent"
      :userId="item.id"
      className="suaw-chip--clickable"
      @click="$emit('chip-click', item)"
    />
    <slot name="chips" />
  </div>
</template>

<style lang="scss" scoped>
.suaw-chip-list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--sem-space-base);
  padding: var(--sem-space-base);
}
</style>
