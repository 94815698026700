import adminGuard from "@/utils/auth/adminGuard";
import seriesOrganizerGuard from "../utils/auth/seriesOrganizerGuard";
import chapterOrganizerGuard from "../utils/auth/chapterOrganizerGuard";
import seriesOnlyGuard from "../utils/auth/seriesOnlyGuard";
import manifestEventGuard from "../utils/auth/manifestEventGuard";

export default [
  {
    path: "/a/event-problems",
    name: "EventProblems",
    beforeEnter: adminGuard,
    component: () => import("@/screensAdmin/seriesManage/EventProblems.vue")
  },
  {
    path: "/chapter/:chapterSlug/series/:seriesSlug/event/:eventId",
    name: "Series",
    component: () => import("../features/series/details/views/Series.vue"),
    beforeEnter: manifestEventGuard
  },
  {
    path: "/chapter/:chapterSlug/series/:seriesId",
    name: "SeriesOnly",
    beforeEnter: seriesOnlyGuard
  },
  {
    path: "/chapter/:chapterId/series-create/:seriesId?",
    name: "SeriesCreate",
    beforeEnter: chapterOrganizerGuard,
    component: () => import("../features/series/create/views/SeriesCreate.vue")
  },
  {
    path: "/chapter/:chapterId/series-edit/:seriesId",
    name: "SeriesEdit",
    beforeEnter: seriesOrganizerGuard,
    component: () => import("../features/series/create/views/SeriesEdit.vue")
  }
];
