export default [
  // {
  //   path: "/resources/organizer/new-organizer-start-here",
  //   name: "NewOrganizerStartHere",
  //   component: () => import("@/screens/resourcesOrganizerObsolete/NewOrganizerStartHere.vue")
  // },
  // {
  //   path: "/resources/organizer/before-your-video-call",
  //   name: "BeforeYourVideoCall",
  //   component: () => import("@/screens/resourcesOrganizerObsolete/BeforeYourVideoCall.vue")
  // },
  {
    path: "/resources/organizer/promoting-your-event",
    name: "PromotingYourEvent",
    component: () => import("@/screens/resourcesOrganizer/PromotingYourEvent.vue")
  },
  {
    path: "/resources/organizer/academic-organizers",
    name: "AcademicOrganizers",
    component: () => import("@/screens/resourcesOrganizer/AcademicOrganizers.vue")
  },
  {
    path: "/resources/organizer/listing-your-in-person-event",
    name: "ListingYourInPersonEvent",
    component: () => import("@/screens/resourcesOrganizer/ListingYourInPersonEvent.vue")
  },
  {
    path: "/resources/organizer/hosting-your-in-person-event",
    name: "HostingYourInPersonEvent",
    component: () => import("@/screens/resourcesOrganizer/HostingYourInPersonEvent.vue")
  },
  {
    path: "/resources/organizer/in-person-event-checklist",
    name: "InPersonEventChecklist",
    component: () => import("@/screens/resourcesOrganizer/InPersonEventChecklist.vue")
  },
  {
    path: "/resources/organizer/listing-your-online-event",
    name: "ListingYourOnlineEvent",
    component: () => import("@/screens/resourcesOrganizer/ListingYourOnlineEvent.vue")
  },
  {
    path: "/resources/organizer/hosting-your-online-event",
    name: "HostingYourOnlineEvent",
    component: () => import("@/screens/resourcesOrganizer/HostingYourOnlineEvent.vue")
  },
  {
    path: "/resources/organizer/online-event-checklist",
    name: "OnlineEventChecklist",
    component: () => import("@/screens/resourcesOrganizer/OnlineEventChecklist.vue")
  },
  {
    path: "/resources/organizer/troubleshooting-at-events",
    name: "TroubleshootingAtEvents",
    component: () => import("@/screens/resourcesOrganizer/TroubleshootingAtEvents.vue")
  },
  {
    path: "/resources/organizer/organizer-faq",
    name: "OrganizerFaq",
    component: () => import("@/screens/resourcesOrganizer/OrganizerFaq.vue"),
    alias: ["/organizer-faq", "/resources/organizer-faq"]
  },
  {
    path: "/resources/organizer/organizer-handbook",
    name: "OrganizerHandbook",
    component: () => import("@/screens/resourcesOrganizer/OrganizerHandbook.vue"),
    alias: ["/organizer-handbook", "/resources/organizer-handbook"]
  },
  {
    path: "/resources/events-toolkit",
    name: "EventsToolkit",
    component: () => import("@/screens/resourcesOrganizer/EventsToolkit.vue"),
    alias: ["/resources/downloads", "/downloads"]
  }
];
