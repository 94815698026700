<script>
import Paragraph from "../Paragraph/Paragraph.vue";
import Chip from "../Chip/Chip.vue";
import Dropdown from "../Dropdown/Dropdown.vue";
import Sidepanel from "../Sidepanel/Sidepanel.vue";
import DirectMessageList from "../DirectMessages/DirectMessageList.vue";
import Pill from "../Pill/Pill.vue";
import AvatarChipPipe from "../Avatar/AvatarChipPipe.vue";

export default {
  name: "InboxItem",
  components: {
    Paragraph,
    Chip,
    Dropdown,
    Sidepanel,
    DirectMessageList,
    Pill,
    AvatarChipPipe,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    userId: {
      type: String,
      required: true,
    },
    totalNotifications: {
      type: Number,
      default: 0
    },
    newResponse: {
      type: String,
      default: "New Response",
    },
    cardText: {
      type: [String, Object],
    },
    posted: {
      type: String,
    },
    dropdownItems: {
      type: Array,
    },
    discussionReplies: {
      type: Array,
      default: () => [],
    },
    isSidepanelDiscussion: {
      type: Boolean,
    },
    editorBoxPrimaryButtonText: {
      type: String,
    },
    editorBoxSecondaryButtonText: {
      type: String,
    },
    textBoxSize: {
      type: String,
    },
    expandableInboxListItems: {
      type: Array,
      default: () => [],
    },
    replyPlaceholder: {
      type: String,
    },
    replyButtonText: {
      type: String,
    },
    sidepanelTitle: {
      type: String,
      default: "",
    },
    newResponseCount: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    cardText(newVal) {
      this.editableText = newVal;
    },
  },
  computed: {
    formattedResponseLabel() {
      return this.newResponseCount === 0 ? 'No New Messages' : `${this.newResponseCount} New Message${this.newResponseCount > 1 ? 's' : ''}`;
    },
    computedChipSize() {
      return this.isSidepanelDiscussion ? "small" : "medium";
    },
  },
  data() {
    return {
      editMode: false,
      editableText: this.cardText,
      isSidepanelOpen: false,
      textAreaReply: false,
      hasSidepanelInboxList: true,
    };
  },
  methods: {
    toggleSidepanel() {
      this.isSidepanelOpen = !this.isSidepanelOpen;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    handleEditorBoxPrimaryButtonClick() {
      const payload = {
        text: this.editableText,
        id: this.id,
      };
      this.$emit("edit-discussion", payload);
      this.toggleEditMode();
    },
    handleMessageClick() {
      this.$emit("message-card-click", this.id);
    }
  },
};
</script>

<template>
  <div class="suaw-card">
    <div class="suaw-card__content">
      <header
        v-if="totalNotifications > 0"
        class="suaw-card__header"
      >
        <Paragraph
          size="small"
          :text="newResponse"
        />
        <Pill
          text=""
          status="critical"
        />
      </header>
      <div
        class="suaw-card__text"
        @click="handleMessageClick"
      >
        <Paragraph
          size="medium"
          weight="normal"
          :text="cardText"
        />
      </div>
      <footer class="suaw-card__footer">
        <div class="suaw-card__block">
          <div class="suaw-card__status-block">
            <AvatarChipPipe
              :size="computedChipSize"
              :userId="userId"
            />
            <span
              :class="{
                'suaw-card__posted--small': isSidepanelDiscussion,
                'suaw-card__posted': !isSidepanelDiscussion
              }"
            >{{ posted }}</span>
          </div>
          <div class="suaw-card__status-block">
            <slot name="discussion-content" />
            <Pill
              v-if="newResponseCount > 0"
              text=""
              status="critical"
            />
            <Chip
              :size="computedChipSize"
              chipType="icon"
              status="info"
              icon="IconComment"
              shape="square"
              :label="formattedResponseLabel"
              class="suaw-chip--clickable"
              @click="handleMessageClick"
            />
            <Dropdown
              iconDropdown="IconMoreHorizontal"
              dropdownButtonSize="small"
              buttonType="secondary-outline"
              buttonClassName="suaw-dropdown__button--chip"
              :items="dropdownItems"
              @editModeToggled="toggleEditMode"
              @item-click="$emit('dropdown-item-click', $event)"
            />
          </div>
          <transition name="suaw-sidepanel__fade">
            <Sidepanel
              v-if="isSidepanelOpen"
              :sidepanelTitle="sidepanelTitle"
              @button-clicked="toggleSidepanel"
            >
              <slot name="direct-messages" />
              <DirectMessageList
                v-if="expandableInboxListItems && expandableInboxListItems.length > 0"
                :notificationListItems="expandableInboxListItems"
                :total-responses="formattedResponseLabel"
              />
            </Sidepanel>
          </transition>
        </div>
      </footer>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.suaw-card {
  background-color: var(--sem-color-background-lightest);
  box-shadow: var(--soft-shadow-draft);
  border-radius: var(--sem-radius-sm);
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sem-space-triple);
  padding: var(--sem-space-triple);

  &__text {
    cursor: pointer;
  }

  &__content,
  &__block,
  &__header,
  &__footer {
    display: flex;
    flex-wrap: wrap;
  }

  &__header {
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__content,
  &__footer {
    flex-direction: column;
    gap: var(--sem-space-triple);
  }

  &__block {
    justify-content: space-between;
  }

  &__status-block {
    display: flex;
    align-items: center;
    gap: var(--sem-space-base);
  }

  &__block:last-child {
    gap: var(--sem-space-triple);
  }

  &__block-reply {
    flex-direction: column;
    position: relative;
  }

  &__posted,
  &__posted--small {
    color: var(--sem-color-text-regular);
  }

  &__posted--small {
    font-size: 12px;
  }

  &__discussion-block {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: var(--sem-space-base);
    width: 100%;
  }
}
</style>
