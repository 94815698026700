<script>
import Button from "../Button/Button.vue";

export default {
  name: "PageHeaderActions",
  components: { Button },
  props: {
    backText: {
      type: String,
      default: "Back"
    },
    shareText: {
      type: String,
      default: "Share"
    },
    backRouteObject: {
      type: Object,
      required: true
    }
  },
  computed: {
    shareUrl() {
      return `https://www.shutupwrite.com${this.$route.fullPath}`;
    }
  },
  methods: {
    async onClickShare() {
      try {
        await navigator.clipboard.writeText(this.shareUrl);
        this.$root.$emit("universal-success-message", "Link copied to clipboard successfully!");
      } catch (err) {
        this.$root.$emit("universal-error-message", "Failed to copy link to clipboard.");
      }
    },
    onBack() {
      this.$router.push(this.backRouteObject);
    }
  }
};
</script>

<template>
  <div class="suaw-page-header-actions">
    <Button
      class="suaw-page-header-actions__back-button suaw-button--no-x-pd suaw-button--no-y-pd"
      icon-left="IconCircleChevronLeft"
      :button-text="backText"
      type="ghost"
      size="large"
      @click="onBack"
    />
    <Button icon-right="IconShareOutline" :button-text="shareText" type="secondary-light" class="suaw-button--pull-right" size="small" @click="onClickShare" />
  </div>
</template>

<style lang="scss" scoped>
.suaw-page-header-actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__back-button {
    height: auto;
  }
}
</style>
