<script>
import NotificationSubscription from "./features/notifications/components/NotificationSubscription.vue";
import MailSubscription from "./features/discussions/components/MailSubscription.vue";
import * as NotificationsApi from "./features/notifications/api";
import GlobalSnackbar from "./features/common/components/GlobalSnackbar.vue";
import DirectMessages from "./features/discussions/components/DirectMessages.vue";
import ComplaintModal from "./features/users/complaint/components/ComplaintModal.vue";
import { SuawHeader, SuawFooter, SuawSidepanel, SuawNotificationList, SuawLoadingState } from "@/components";
import { useLoading } from "@/utils/plugins/loadingState";

export default {
  name: "App",
  components: {
    SuawHeader,
    SuawFooter,
    SuawSidepanel,
    SuawNotificationList,
    SuawLoadingState,
    GlobalSnackbar,
    NotificationSubscription,
    MailSubscription,
    DirectMessages,
    ComplaintModal
  },
  provide() {
    return {
      userId: () => this.$auth.user?.id
    };
  },
  metaInfo: {
    titleTemplate: "%s Shut Up & Write",
    title: "Writing Community - ",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Shut Up & Write! hosts free in-person and online writing events for writers around the world."
      }
    ]
  },
  setup() {
    const { isLoading } = useLoading();
    return { isLoading };
  },
  data() {
    return {
      mailCount: 0,
      notificationCount: 0,
      showNotificationPanel: false,
      eventLinks: [
        { text: "In-Person Events", url: "/chapters" },
        { text: "Online Events", url: "/online-events" }
      ],
      aboutLinks: [
        { text: "About Shut Up & Write!", url: "/about/suaw" },
        { text: "Our Method", url: "/about/method" },
        { text: "Our Organization", url: "/about/organization" },
        { text: "Our Impact", url: "/about/impact" },
        { text: "Contact Us", url: "/resources/contact" }
      ],
      baseNavLinks: [
        { text: "Resources", url: "/resources" },
        { text: "Store", url: "https://store.shutupwrite.com/", external: true }
      ],
      authenticatedUserLinks: [
        { icon: "IconCalendar", text: "My Events", url: "/dashboard", showAvatar: false },
        { icon: "IconUserCircle", text: "Profile", url: "/dashboard/profile", showAvatar: false },
        { icon: "IconMail", text: "Inbox", url: "/dashboard/mail", showAvatar: false },
        { icon: "IconNotificationOutline", text: "Notifications", function: () => this.openNotificationsClicked(), showAvatar: false },
        { icon: "IconSettingsFuture", text: "Settings", url: "/dashboard/settings", showAvatar: false },
        { icon: "IconExit", text: "Log out", function: () => this.logout(), showAvatar: false }
      ],
      notificationItems: [
        // {
        //   id: "",
        //   text: "",
        //   posted: "",
        //   hasLink: false
        //   linkContent: ""
        // }
      ]
    };
  },
  computed: {
    authenticatedUsername() {
      if (this.$auth.isAuthenticated && this.$auth.user) {
        return this.$auth.user.display_name;
      } else {
        return "";
      }
    },
    reactiveAuthenticatedUserLinks() {
      let result = this.authenticatedUserLinks.map(item => {
        if (item.icon === "IconNotificationOutline" && this.notificationCount > 0) {
          return { ...item, notificationCounter: this.notificationCount.toString() };
        }
        if (item.icon === "IconMail" && this.mailCount > 0) {
          return { ...item, notificationCounter: this.mailCount.toString() };
        }
        return item;
      });
      if (this.$auth.isAdmin) {
        result.splice(0, 0, { icon: "IconHouse", text: "Admin", url: "/a" });
      }
      return result;
    },
    computedNavLinks() {
      if (this.$auth.isAdmin) {
        return [{ text: "Challenges", url: "/challenges" }, ...this.baseNavLinks];
      }
      return this.baseNavLinks;
    },
    totalCount() {
      const total = this.notificationCount + this.mailCount;
      if (total > 0) {
        return total.toString();
      } else {
        return undefined;
      }
    }
  },

  methods: {
    login(redirectUrl) {
      if (redirectUrl === "/") {
        this.$router.push({
          name: "LogIn"
        });
      } else {
        this.$router.push({
          name: "LogIn",
          query: { redirectUrl }
        });
      }
    },
    logout() {
      this.$auth.logout({ redirectLocation: { name: "LogIn" } });
    },
    async notificationCountUpdated(val) {
      this.notificationCount = val;
      await this.$refs.notificationSubscription.$apollo.queries.resultUserNotifications.refetch();
    },
    mailCountUpdated(val) {
      this.mailCount = val;
    },
    async notificationsUpdated(val) {
      this.notificationItems = val;
    },
    openNotificationsClicked() {
      this.showNotificationPanel = true;
    },
    closeNotificationsClicked() {
      this.showNotificationPanel = false;
      this.markUnseenNotificationsAsSeen();
    },
    async markUnseenNotificationsAsSeen() {
      try {
        const result = await NotificationsApi.markInbox(null, this.$auth.user.id, "Seen");
        if (!result.success) {
          this.$root.$emit("universal-error-message", result.error);
        }
      } catch (error) {
        this.$root.$emit("universal-error-message", error.message);
      }
      // Refetch notifications
      await this.$refs.notificationSubscription.$apollo.queries.resultUserNotifications.refetch();
    },
    handleLinkClick({ linkContent }) {
      this.$router.push(linkContent);
      this.closeNotificationsClicked();
    }
  }
};
</script>

<template>
  <main class="suaw-app">
    <NotificationSubscription v-if="$auth.isAuthenticated" ref="notificationSubscription" @updatedCount="notificationCountUpdated" @updatedCollection="notificationsUpdated" />
    <MailSubscription v-if="$auth.isAuthenticated" @updated="mailCountUpdated" />
    <SuawHeader
      :is-authenticated="$auth.isAuthenticated"
      :show-avatar="$auth.isAuthenticated"
      :user-name="authenticatedUsername"
      :total-notifications="totalCount"
      :event-links="eventLinks"
      :about-links="aboutLinks"
      :nav-links="computedNavLinks"
      :authenticated-user-links="reactiveAuthenticatedUserLinks"
      @login-click="login"
    />
    <GlobalSnackbar />
    <SuawSidepanel v-if="showNotificationPanel" sidepanel-title="Notifications" @button-clicked="closeNotificationsClicked">
      <SuawNotificationList id="notifications" :notification-list-items="notificationItems" :has-read-all="false" @link-click="handleLinkClick" />
    </SuawSidepanel>
    <DirectMessages v-if="$auth.isAuthenticated" />
    <transition name="fade" mode="out-in">
      <SuawLoadingState v-if="isLoading.isLoading" />
      <router-view v-else />
    </transition>
    <ComplaintModal />
    <SuawFooter />
  </main>
</template>

<style lang="css" scoped>
.suaw-app {
  background: linear-gradient(180deg, var(--sem-color-info-light) 0%, var(--sem-color-background-light) 30%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
