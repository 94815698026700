export default [
  {
    path: "/resources/get-involved",
    name: "GetInvolved",
    component: () => import("@/screens/resourcesGeneral/GetInvolved.vue"),
    alias: ["/about/get-involved"]
  },
  {
    path: "/resources/become-an-organizer",
    name: "BecomeAnOrganizer",
    component: () => import("@/screens/resourcesGeneral/BecomeAnOrganizer.vue"),
    alias: ["/host", "/organizer", "/resources/organizer"]
  },
  {
    path: "/resources/faq",
    name: "Faq",
    component: () => import("@/screens/resourcesGeneral/Faq.vue"),
    alias: ["/about/faq", "/faq"]
  }
];
